// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.bs.js";
import * as LocalStorage$LiteracyplanetClientFlags from "./LocalStorage.bs.js";
import * as CobraGenericBehaviourContext$LiteracyplanetClientFlags from "./CobraGenericBehaviourContext.bs.js";

var key = "areSoundEffectsEnabled";

function toString(t) {
  return Belt_Option.getExn(JSON.stringify(t));
}

function fromString(data) {
  return Belt_Option.map(data, (function (d) {
                return JSON.parse(d);
              }));
}

var S = {
  key: key,
  toString: toString,
  fromString: fromString
};

var $$Storage = LocalStorage$LiteracyplanetClientFlags.Make({
      key: key,
      fromString: fromString,
      toString: toString
    });

function use(param) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var match = Curry._1($$Storage.useLocalStorageWithNamespace, userId);
  return [
          Belt_Option.getWithDefault(match[0], true),
          match[1]
        ];
}

var $$Storage$1 = {
  S: S,
  $$Storage: $$Storage,
  use: use
};

function CobraGenericBehaviour(Props) {
  var children = Props.children;
  var myFont = Props.myFont;
  var match = use(undefined);
  var setStorage = match[1];
  return React.createElement(CobraGenericBehaviourContext$LiteracyplanetClientFlags.Provider.make, {
              value: {
                props: {
                  areSoundEffectsEnabled: match[0],
                  myFont: myFont
                },
                onDispatch: {
                  onEnableSoundEffects: (function (param) {
                      return Curry._1(setStorage, true);
                    }),
                  onDisableSoundEffects: (function (param) {
                      return Curry._1(setStorage, false);
                    })
                }
              },
              children: children
            });
}

var make = CobraGenericBehaviour;

export {
  $$Storage$1 as $$Storage,
  make ,
  
}
/* Storage Not a pure module */
