// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as OnError$LiteracyplanetClientFlags from "./OnError.bs.js";

function MapResult(Props) {
  var result = Props.result;
  var onOkOpt = Props.onOk;
  var onOk = onOkOpt !== undefined ? onOkOpt : (function (param) {
        return null;
      });
  var onError = Curry._1(OnError$LiteracyplanetClientFlags.use, undefined);
  if (result.TAG === /* Ok */0) {
    return Curry._1(onOk, result._0);
  } else {
    return Curry._1(onError, result._0);
  }
}

var make = MapResult;

export {
  make ,
  
}
/* OnError-LiteracyplanetClientFlags Not a pure module */
