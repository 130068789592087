// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Client from "@apollo/client";
import * as StudentFragments$LiteracyplanetClientFlags from "./StudentFragments.bs.js";

var Raw = {};

var query = Client.gql([
      "fragment CurrentStudentFragment on Query   {\ncurrentStudent(userId: $userId)  {\n...StudentDetailsFragment   \n}\n\n}\n",
      ""
    ], StudentFragments$LiteracyplanetClientFlags.StudentDetailsFragment.query);

function parse(value) {
  var value$1 = value.currentStudent;
  return {
          currentStudent: !(value$1 == null) ? StudentFragments$LiteracyplanetClientFlags.StudentDetailsFragment.verifyArgsAndParse("StudentDetailsFragment", value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent = value$1 !== undefined ? StudentFragments$LiteracyplanetClientFlags.StudentDetailsFragment.serialize(value$1) : null;
  return {
          currentStudent: currentStudent
        };
}

function verifyArgsAndParse(_userId, _CurrentStudentFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var CurrentStudentFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql([
      "fragment CurrentStudentIncompleteVisibleMissionsFragment on Query   {\ncurrentStudent(userId: $userId)  {\n...IncompleteVisibleMissionsFragment   \n}\n\n}\n",
      ""
    ], StudentFragments$LiteracyplanetClientFlags.IncompleteVisibleMissionsFragment.query);

function parse$1(value) {
  var value$1 = value.currentStudent;
  return {
          currentStudent: !(value$1 == null) ? StudentFragments$LiteracyplanetClientFlags.IncompleteVisibleMissionsFragment.verifyArgsAndParse("IncompleteVisibleMissionsFragment", value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.currentStudent;
  var currentStudent = value$1 !== undefined ? StudentFragments$LiteracyplanetClientFlags.IncompleteVisibleMissionsFragment.serialize(value$1) : null;
  return {
          currentStudent: currentStudent
        };
}

function verifyArgsAndParse$1(_userId, _CurrentStudentIncompleteVisibleMissionsFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var CurrentStudentIncompleteVisibleMissionsFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = Client.gql([
      "fragment CurrentStudentAvatarFragment on Query   {\ncurrentStudent(userId: $userId)  {\n...AvatarFragment   \n}\n\n}\n",
      ""
    ], StudentFragments$LiteracyplanetClientFlags.AvatarFragment.query);

function parse$2(value) {
  var value$1 = value.currentStudent;
  return {
          currentStudent: !(value$1 == null) ? StudentFragments$LiteracyplanetClientFlags.AvatarFragment.verifyArgsAndParse("AvatarFragment", value$1) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.currentStudent;
  var currentStudent = value$1 !== undefined ? StudentFragments$LiteracyplanetClientFlags.AvatarFragment.serialize(value$1) : null;
  return {
          currentStudent: currentStudent
        };
}

function verifyArgsAndParse$2(_userId, _CurrentStudentAvatarFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var CurrentStudentAvatarFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = Client.gql([
      "fragment CurrentStudentActivityContentFragment on Query   {\ncurrentStudent(userId: $userId)  {\n...ActivityContentFragment   \n}\n\n}\n",
      ""
    ], StudentFragments$LiteracyplanetClientFlags.ActivityContentFragment.query);

function parse$3(value) {
  var value$1 = value.currentStudent;
  return {
          currentStudent: !(value$1 == null) ? StudentFragments$LiteracyplanetClientFlags.ActivityContentFragment.verifyArgsAndParse("Accent", "Locale", "NonNull_Int", "ActivityContentFragment", value$1) : undefined
        };
}

function serialize$3(value) {
  var value$1 = value.currentStudent;
  var currentStudent = value$1 !== undefined ? StudentFragments$LiteracyplanetClientFlags.ActivityContentFragment.serialize(value$1) : null;
  return {
          currentStudent: currentStudent
        };
}

function verifyArgsAndParse$3(_accent, _locale, _studentActivityId, _userId, _CurrentStudentActivityContentFragment, value) {
  return parse$3(value);
}

function verifyName$3(param) {
  
}

var CurrentStudentActivityContentFragment = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  verifyArgsAndParse: verifyArgsAndParse$3,
  verifyName: verifyName$3
};

var StudentDetailsFragment;

var IncompleteVisibleMissionsFragment;

var AvatarFragment;

var ActivityContentFragment;

export {
  StudentDetailsFragment ,
  CurrentStudentFragment ,
  IncompleteVisibleMissionsFragment ,
  CurrentStudentIncompleteVisibleMissionsFragment ,
  AvatarFragment ,
  CurrentStudentAvatarFragment ,
  ActivityContentFragment ,
  CurrentStudentActivityContentFragment ,
  
}
/* query Not a pure module */
