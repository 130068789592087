// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Uuid$LiteracyplanetTupperware from "../Uuid.bs.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as AudioPillButton$LiteracyplanetTupperware from "./AudioPillButton.bs.js";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/SoundProviderEmbedded.bs.js";

function ImageSoundQuestion$ImageSoundQuestionGrid(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.ImageSoundQuestionGrid.className
            }, children);
}

var ImageSoundQuestionGrid = {
  make: ImageSoundQuestion$ImageSoundQuestionGrid
};

function ImageSoundQuestion$WithAudioEmbedded(Props) {
  var audioId = Props.audioId;
  var sources = Props.sources;
  var children = Props.children;
  var instance = React.useMemo((function () {
          return Uuid$LiteracyplanetTupperware.make(undefined);
        }), [audioId]);
  var match = SoundProviderEmbedded$LiteracyplanetClientFlags.use(instance, audioId, false, sources);
  return React.createElement(React.Fragment, undefined, React.createElement(AudioPillButton$LiteracyplanetTupperware.make, {
                  isPlaying: match[0],
                  src: match[2],
                  onTogglePlay: match[1]
                }), children);
}

var WithAudioEmbedded = {
  make: ImageSoundQuestion$WithAudioEmbedded
};

function ImageSoundQuestion$AnswerImage(Props) {
  var imageSrc = Props.imageSrc;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerImage.imageDivClassName
            }, React.createElement("img", {
                  className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerImage.imageCss,
                  src: imageSrc
                }));
}

var AnswerImage = {
  make: ImageSoundQuestion$AnswerImage
};

function ImageSoundQuestion$AnswerText(Props) {
  var image = Props.image;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, image, React.createElement("div", {
                  className: Flavor$LiteracyplanetTupperware.ImageSoundQuestion.AnswerText.className
                }, children));
}

var AnswerText = {
  make: ImageSoundQuestion$AnswerText
};

function ImageSoundQuestion(Props) {
  var audioId = Props.audioId;
  var sources = Props.sources;
  var imageSrc = Props.imageSrc;
  var children = Props.children;
  var image = Belt_Option.mapWithDefault(imageSrc, null, (function (imageSrc) {
          return React.createElement(ImageSoundQuestion$AnswerImage, {
                      imageSrc: imageSrc
                    });
        }));
  var content = React.createElement(ImageSoundQuestion$AnswerText, {
        image: image,
        children: children
      });
  return React.createElement(ImageSoundQuestion$ImageSoundQuestionGrid, {
              children: Belt_Option.mapWithDefault(audioId, content, (function (audioId) {
                      if (sources !== undefined) {
                        return React.createElement(ImageSoundQuestion$WithAudioEmbedded, {
                                    audioId: audioId,
                                    sources: sources,
                                    children: content
                                  });
                      } else {
                        return content;
                      }
                    }))
            });
}

var make = ImageSoundQuestion;

var $$default = ImageSoundQuestion;

export {
  ImageSoundQuestionGrid ,
  WithAudioEmbedded ,
  AnswerImage ,
  AnswerText ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
