export default ({
  view,
  width,
  height,
  name,
  props,
  onDispatch
}) => {
  let cobraConfig = {
    name,
    width, // width of the viewport as opposed to width of div
    height, // height of the viewport as opposed to height of div
    props, // view params {items, skin}
    dispatch: onDispatch
  };

  const init = (view) => {
    const cb = view.default(cobraConfig);
    const cleanup = cb.cleanup ? cb.cleanup : () => {
      console.log('Please export a cleanup function from the cobra view');
    };

    const render = cb.render ? cb.render : () => {
      console.log('Please export a render function from the cobra view');
    };

    return {
      cleanup,
      render
    };
  };

  switch (view) {
    case 'st': return import('@lpcobra/sound_train').then(init);
    case 'fc': return import('@lpcobra/flash_card').then(init);
    case 'stw': return import('@lpcobra/spell_this_word').then(init);
    case 'ss': return import('@lpcobra/stubmunk_sentence').then(init);
    case 'avatar': return import('@lpcobra/avatar_container').then(init);
    case 'avatarExporter': return import('@lpcobra/avatar_container').then(init);
    // case 'results': return import('@lpcobra/results').then(init);
    // case 'resultsPaper': return import('@lpcobra/results_paper').then(init);
    case 'results': return import('@lpcobra/results_paper').then(init);
    case 'literacyCheckComplete': return import('@lpcobra/literacy_check_complete').then(init);
    // case 'wordTemple': return import('@lpcobra/wt_mode_free_play').then(init);
    case 'collections': return import('@lpcobra/collections').then(init);
    case 'shop': return import('@lpcobra/shop').then(init);
    case 'mail': return import('@lpcobra/mail').then(init);
    case 'fwm': return import('@lpcobra/feed_word_monster').then(init);
    case 'smc': return import('@lpcobra/stubmunk_multiple_choice').then(init);
    case 'wam': return import('@lpcobra/whack_a_munk').then(init);
    case 'mc': return import('@lpcobra/monster_chef').then(init);
    case 'wb': return import('@lpcobra/word_builder').then(init);
    case 'cp': return import('@lpcobra/code_phrase').then(init);
    case 'mem': return import('@lpcobra/memory_game').then(init);
    case 'fw': return import('@lpcobra/floating_words').then(init);
    case 'fwaPenguin': return import('@lpcobra/floating_words_alphabetical').then(init);
    case 'fwaCroc': return import('@lpcobra/floating_words_alphabetical').then(init);
    case 'sw': return import('@lpcobra/static_words').then(init);
    case 'jwt': return import('@lpcobra/journey_welcome_tutorial').then(init);
    case 'jms': return import('@lpcobra/journey_mission_select').then(init);
    case 'jas': return import('@lpcobra/journey_activity_select').then(init);
    case 'ws': return import('@lpcobra/word_sort').then(init);
    case 'fcu': return import('@lpcobra/flash_card_upper').then(init);
    case 'jig': return import('@lpcobra/jigsaw').then(init);
    case 'sj': return import('@lpcobra/sentence_jumble').then(init);
    case 'ld': return import('@lpcobra/letter_drop').then(init);
    case 'sc': return import('@lpcobra/sound_card').then(init);
    case 'sh': return import('@lpcobra/sound_hunt').then(init);
    case 'lh': return import('@lpcobra/letter_hunt').then(init);
    case 'wh': return import('@lpcobra/word_hunt').then(init);
    case 'ph': return import('@lpcobra/picture_hunt').then(init);
    case 'sbtn': return import('@lpcobra/sound_button').then(init);
    case 'pwp': return import('@lpcobra/picture_word_puzzle').then(init);
    case 'sllp': return import('@lpcobra/slider_letter_to_picture').then(init);
    case 'slpl': return import('@lpcobra/slider_picture_to_letter').then(init);
    case 'slpw': return import('@lpcobra/slider_picture_to_word').then(init);
    case 'slwp': return import('@lpcobra/slider_word_to_picture').then(init);
    case 'hw': return import('@lpcobra/handwriting').then(init);
    case 'sb': return import('@lpcobra/sheep_bounce').then(init);
    case 'bs': return import('@lpcobra/block_stack').then(init);
    case 'awm': return import('@lpcobra/arcade_word_morph').then(init);
    case 'penpen': return import('@lpcobra/penpen').then(init);
    case 'tk': return import('@lpcobra/turtle_knock').then(init);
    case 'hm': return import('@lpcobra/hangman').then(init);
    case 'arcadeWM': return import('@lpcobra/arcade_word_mania').then(init);
    case 'snap': return import('@lpcobra/snap').then(init);
    case 'wbDesert': return import('@lpcobra/word_builder_waterfall').then(init);
    case 'wbWaterfall': return import('@lpcobra/word_builder_waterfall').then(init);
    case 'wbCard': return import('@lpcobra/word_builder_card').then(init);
    case 'wf': return import('@lpcobra/word_finder').then(init);
    case 'wm': return import('@lpcobra/word_mania').then(init);
    case 'tc': return import('@lpcobra/treasure_crab').then(init);
    case 'bp': return import('@lpcobra/breakout_penguin').then(init);
    case 'wap': return import('@lpcobra/whack_a_pirate').then(init);
    default: throw new Error('Invalid view name: \'' + view + '\'');
  }

  // TODO dynamically import the view based on view param?
  // return import(view).then(init);
};
