// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as GameContentFragments$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/GameContentFragments.bs.js";

var Raw = {};

var query = Client.gql([
      "query checksActivityQuery($userId: String!)  {\nliteracy_checks_outstanding_activities(limit: 1, where: {activityComplete: {_eq: false}})  {\n__typename  \ncontentId  \ngameId  \ncontentKind  \nactivityName  \nlocale  \naccent  \nassignationId  \nactivityId  \nstate  {\n__typename  \nstate  \ncontentMd5  \n}\n\nattempt  {\n__typename  \nid  \nresponses(order_by: [{questionIndex: desc}])  {\n__typename  \nquestionIndex  \npoints  \n}\n\n}\n\ncontent  {\n__typename\n...on WordGameData   {\n...WordGameDataFragment   \n}\n\n...on ComprehensionPlaceholderData   {\n__typename  \nid  \n}\n\n...on VideoData   {\n...VideoDataFragment   \n}\n\n...on TupperwareData   {\n...TupperwareDataFragment   \n}\n\n...on WhackAMunkData   {\n...WhackAMunkDataFragment   \n}\n\n...on SoundTrainData   {\n...SoundTrainDataFragment   \n}\n\n...on MonsterChefData   {\n...MonsterChefDataFragment   \n}\n\n...on InternalStaticData   {\n...InternalStaticDataFragment   \n}\n\n...on UnsafeData   {\n...UnsafeDataFragment   \n}\n\n...on UnkownGameType   {\n__typename  \nerror  \n}\n\n}\n\n}\n\ncurrentStudent(userId: $userId)  {\n__typename  \nuserId  \navatar: avatarB  {\n__typename  \nid  \nskin  {\n__typename  \nid  \ntint  \n}\n\nitems  {\n__typename  \nid  \ntextures  \ntint  \nslot  {\n__typename  \nid  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ], GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.query, GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.query);

function parse(value) {
  var value$1 = value.literacy_checks_outstanding_activities;
  var value$2 = value.currentStudent;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.userId;
    var value$4 = value$2.avatar;
    var tmp$1;
    if (value$4 == null) {
      tmp$1 = undefined;
    } else {
      var value$5 = value$4.skin;
      var value$6 = value$4.items;
      tmp$1 = {
        __typename: value$4.__typename,
        id: value$4.id,
        skin: !(value$5 == null) ? ({
              __typename: value$5.__typename,
              id: value$5.id,
              tint: value$5.tint
            }) : undefined,
        items: value$6.map(function (value) {
              if (value == null) {
                return ;
              }
              var value$1 = value.textures;
              var value$2 = value.tint;
              var value$3 = value.slot;
              return {
                      __typename: value.__typename,
                      id: value.id,
                      textures: value$1.map(function (value) {
                            return value;
                          }),
                      tint: value$2.map(function (value) {
                            return value;
                          }),
                      slot: {
                        __typename: value$3.__typename,
                        id: value$3.id,
                        name: value$3.name
                      }
                    };
            })
      };
    }
    tmp = {
      __typename: value$2.__typename,
      userId: !(value$3 == null) ? value$3 : undefined,
      avatar: tmp$1
    };
  }
  return {
          literacy_checks_outstanding_activities: value$1.map(function (value) {
                var value$1 = value.contentId;
                var value$2 = value.gameId;
                var value$3 = value.contentKind;
                var value$4 = value.activityName;
                var value$5 = value.locale;
                var value$6 = value.accent;
                var tmp;
                switch (value$6) {
                  case "American" :
                      tmp = "American";
                      break;
                  case "Australian" :
                      tmp = "Australian";
                      break;
                  case "British" :
                      tmp = "British";
                      break;
                  case "Texan" :
                      tmp = "Texan";
                      break;
                  default:
                    throw {
                          RE_EXN_ID: "Not_found",
                          Error: new Error()
                        };
                }
                var value$7 = value.assignationId;
                var value$8 = value.activityId;
                var value$9 = value.state;
                var tmp$1;
                if (value$9 == null) {
                  tmp$1 = undefined;
                } else {
                  var value$10 = value$9.contentMd5;
                  tmp$1 = {
                    __typename: value$9.__typename,
                    state: value$9.state,
                    contentMd5: !(value$10 == null) ? value$10 : undefined
                  };
                }
                var value$11 = value.attempt;
                var tmp$2;
                if (value$11 == null) {
                  tmp$2 = undefined;
                } else {
                  var value$12 = value$11.responses;
                  tmp$2 = {
                    __typename: value$11.__typename,
                    id: value$11.id,
                    responses: value$12.map(function (value) {
                          return {
                                  __typename: value.__typename,
                                  questionIndex: value.questionIndex,
                                  points: value.points
                                };
                        })
                  };
                }
                var value$13 = value.content;
                var typename = value$13["__typename"];
                var tmp$3;
                switch (typename) {
                  case "ComprehensionPlaceholderData" :
                      tmp$3 = {
                        NAME: "ComprehensionPlaceholderData",
                        VAL: {
                          __typename: value$13.__typename,
                          id: value$13.id
                        }
                      };
                      break;
                  case "InternalStaticData" :
                      tmp$3 = {
                        NAME: "InternalStaticData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.verifyArgsAndParse("InternalStaticDataFragment", value$13)
                      };
                      break;
                  case "MonsterChefData" :
                      tmp$3 = {
                        NAME: "MonsterChefData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.verifyArgsAndParse("MonsterChefDataFragment", value$13)
                      };
                      break;
                  case "SoundTrainData" :
                      tmp$3 = {
                        NAME: "SoundTrainData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.verifyArgsAndParse("SoundTrainDataFragment", value$13)
                      };
                      break;
                  case "TupperwareData" :
                      tmp$3 = {
                        NAME: "TupperwareData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.verifyArgsAndParse("TupperwareDataFragment", value$13)
                      };
                      break;
                  case "UnkownGameType" :
                      var value$14 = value$13.error;
                      tmp$3 = {
                        NAME: "UnkownGameType",
                        VAL: {
                          __typename: value$13.__typename,
                          error: !(value$14 == null) ? value$14 : undefined
                        }
                      };
                      break;
                  case "UnsafeData" :
                      tmp$3 = {
                        NAME: "UnsafeData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.verifyArgsAndParse("UnsafeDataFragment", value$13)
                      };
                      break;
                  case "VideoData" :
                      tmp$3 = {
                        NAME: "VideoData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.verifyArgsAndParse("VideoDataFragment", value$13)
                      };
                      break;
                  case "WhackAMunkData" :
                      tmp$3 = {
                        NAME: "WhackAMunkData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.verifyArgsAndParse("WhackAMunkDataFragment", value$13)
                      };
                      break;
                  case "WordGameData" :
                      tmp$3 = {
                        NAME: "WordGameData",
                        VAL: GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.verifyArgsAndParse("WordGameDataFragment", value$13)
                      };
                      break;
                  default:
                    tmp$3 = {
                      NAME: "FutureAddedValue",
                      VAL: value$13
                    };
                }
                return {
                        __typename: value.__typename,
                        contentId: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                        gameId: !(value$2 == null) ? value$2 : undefined,
                        contentKind: !(value$3 == null) ? value$3 : undefined,
                        activityName: !(value$4 == null) ? value$4 : undefined,
                        locale: !(value$5 == null) ? value$5 : undefined,
                        accent: tmp,
                        assignationId: !(value$7 == null) ? Caml_option.some(value$7) : undefined,
                        activityId: !(value$8 == null) ? Caml_option.some(value$8) : undefined,
                        state: tmp$1,
                        attempt: tmp$2,
                        content: tmp$3
                      };
              }),
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.avatar;
    var avatar;
    if (value$2 !== undefined) {
      var value$3 = value$2.items;
      var items = value$3.map(function (value) {
            if (value === undefined) {
              return null;
            }
            var value$1 = value.slot;
            var value$2 = value$1.name;
            var value$3 = value$1.id;
            var value$4 = value$1.__typename;
            var slot = {
              __typename: value$4,
              id: value$3,
              name: value$2
            };
            var value$5 = value.tint;
            var tint = value$5.map(function (value) {
                  return value;
                });
            var value$6 = value.textures;
            var textures = value$6.map(function (value) {
                  return value;
                });
            var value$7 = value.id;
            var value$8 = value.__typename;
            return {
                    __typename: value$8,
                    id: value$7,
                    textures: textures,
                    tint: tint,
                    slot: slot
                  };
          });
      var value$4 = value$2.skin;
      var skin;
      if (value$4 !== undefined) {
        var value$5 = value$4.tint;
        var value$6 = value$4.id;
        var value$7 = value$4.__typename;
        skin = {
          __typename: value$7,
          id: value$6,
          tint: value$5
        };
      } else {
        skin = null;
      }
      var value$8 = value$2.id;
      var value$9 = value$2.__typename;
      avatar = {
        __typename: value$9,
        id: value$8,
        skin: skin,
        items: items
      };
    } else {
      avatar = null;
    }
    var value$10 = value$1.userId;
    var userId = value$10 !== undefined ? value$10 : null;
    var value$11 = value$1.__typename;
    currentStudent = {
      __typename: value$11,
      userId: userId,
      avatar: avatar
    };
  } else {
    currentStudent = null;
  }
  var value$12 = value.literacy_checks_outstanding_activities;
  var literacy_checks_outstanding_activities = value$12.map(function (value) {
        var value$1 = value.content;
        var variant = value$1.NAME;
        var content;
        if (variant === "ComprehensionPlaceholderData") {
          var value$2 = value$1.VAL.id;
          content = {
            __typename: "ComprehensionPlaceholderData",
            id: value$2
          };
        } else if (variant === "SoundTrainData") {
          content = GameContentFragments$LiteracyplanetClientFlags.SoundTrainDataFragment.serialize(value$1.VAL);
        } else if (variant === "TupperwareData") {
          content = GameContentFragments$LiteracyplanetClientFlags.TupperwareDataFragment.serialize(value$1.VAL);
        } else if (variant === "FutureAddedValue") {
          content = value$1.VAL;
        } else if (variant === "VideoData") {
          content = GameContentFragments$LiteracyplanetClientFlags.VideoDataFragment.serialize(value$1.VAL);
        } else if (variant === "UnsafeData") {
          content = GameContentFragments$LiteracyplanetClientFlags.UnsafeDataFragment.serialize(value$1.VAL);
        } else if (variant === "UnkownGameType") {
          var value$3 = value$1.VAL.error;
          var error = value$3 !== undefined ? value$3 : null;
          content = {
            __typename: "UnkownGameType",
            error: error
          };
        } else {
          content = variant === "MonsterChefData" ? GameContentFragments$LiteracyplanetClientFlags.MonsterChefDataFragment.serialize(value$1.VAL) : (
              variant === "InternalStaticData" ? GameContentFragments$LiteracyplanetClientFlags.InternalStaticDataFragment.serialize(value$1.VAL) : (
                  variant === "WhackAMunkData" ? GameContentFragments$LiteracyplanetClientFlags.WhackAMunkDataFragment.serialize(value$1.VAL) : GameContentFragments$LiteracyplanetClientFlags.WordGameDataFragment.serialize(value$1.VAL)
                )
            );
        }
        var value$4 = value.attempt;
        var attempt;
        if (value$4 !== undefined) {
          var value$5 = value$4.responses;
          var responses = value$5.map(function (value) {
                var value$1 = value.points;
                var value$2 = value.questionIndex;
                var value$3 = value.__typename;
                return {
                        __typename: value$3,
                        questionIndex: value$2,
                        points: value$1
                      };
              });
          var value$6 = value$4.id;
          var value$7 = value$4.__typename;
          attempt = {
            __typename: value$7,
            id: value$6,
            responses: responses
          };
        } else {
          attempt = null;
        }
        var value$8 = value.state;
        var state;
        if (value$8 !== undefined) {
          var value$9 = value$8.contentMd5;
          var contentMd5 = value$9 !== undefined ? value$9 : null;
          var value$10 = value$8.state;
          var value$11 = value$8.__typename;
          state = {
            __typename: value$11,
            state: value$10,
            contentMd5: contentMd5
          };
        } else {
          state = null;
        }
        var value$12 = value.activityId;
        var activityId = value$12 !== undefined ? Caml_option.valFromOption(value$12) : null;
        var value$13 = value.assignationId;
        var assignationId = value$13 !== undefined ? Caml_option.valFromOption(value$13) : null;
        var value$14 = value.accent;
        var accent = value$14 === "American" ? "American" : (
            value$14 === "British" ? "British" : (
                value$14 === "Texan" ? "Texan" : "Australian"
              )
          );
        var value$15 = value.locale;
        var locale = value$15 !== undefined ? value$15 : null;
        var value$16 = value.activityName;
        var activityName = value$16 !== undefined ? value$16 : null;
        var value$17 = value.contentKind;
        var contentKind = value$17 !== undefined ? value$17 : null;
        var value$18 = value.gameId;
        var gameId = value$18 !== undefined ? value$18 : null;
        var value$19 = value.contentId;
        var contentId = value$19 !== undefined ? Caml_option.valFromOption(value$19) : null;
        var value$20 = value.__typename;
        return {
                __typename: value$20,
                contentId: contentId,
                gameId: gameId,
                contentKind: contentKind,
                activityName: activityName,
                locale: locale,
                accent: accent,
                assignationId: assignationId,
                activityId: activityId,
                state: state,
                attempt: attempt,
                content: content
              };
      });
  return {
          literacy_checks_outstanding_activities: literacy_checks_outstanding_activities,
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function onLoading(param) {
  return /* LoadingActivityContent */0;
}

function handleResponse(res) {
  return /* ActivityContentFound */{
          _0: res
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useQuery(userId) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId
            });
}

function use$1(param) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  return useQuery(user.userId);
}

var WordGameDataFragment;

var MonsterChefDataFragment;

var WhackAMunkDataFragment;

var SoundTrainDataFragment;

var TupperwareDataFragment;

var InternalStaticDataFragment;

var UnsafeDataFragment;

var VideoDataFragment;

export {
  WordGameDataFragment ,
  MonsterChefDataFragment ,
  WhackAMunkDataFragment ,
  SoundTrainDataFragment ,
  TupperwareDataFragment ,
  InternalStaticDataFragment ,
  UnsafeDataFragment ,
  VideoDataFragment ,
  Query ,
  onLoading ,
  handleResponse ,
  onResult ,
  Q ,
  useQuery ,
  use$1 as use,
  
}
/* query Not a pure module */
