// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import BackgroundJpg from "./assets/background.jpg";
import Tile_backgroundSvg from "./assets/tile_background.svg";
import Void_backgroundWebp from "./assets/void_background.webp";
import * as Wrap$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Wrap.bs.js";
import * as PageTypes$LiteracyplanetClientSxEntry from "../PageTypes.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as MarginPanel$LiteracyplanetClientSxEntry from "./MarginPanel.bs.js";
import * as MobileUtils$LiteracyplanetClientSxEntry from "../MobileUtils.bs.js";
import * as ForestNavBar$LiteracyplanetClientSxEntry from "./ForestNavBar.bs.js";
import * as BubbleNavBarRatio$LiteracyplanetClientSxEntry from "./BubbleNavBarRatio.bs.js";
import * as InitialsMenuRatio$LiteracyplanetClientSxEntry from "./InitialsMenuRatio.bs.js";
import * as ModernNavBarRatio$LiteracyplanetClientSxEntry from "./ModernNavBarRatio.bs.js";
import * as BackgroundComponent$LiteracyplanetClientSxEntry from "./BackgroundComponent.bs.js";
import * as JourneyInitialsMenu$LiteracyplanetClientSxEntry from "./JourneyInitialsMenu.bs.js";

var backgroundSrc = BackgroundJpg;

var intrepizineBackgroundSrc = Tile_backgroundSvg;

var voidBackgroundSrc = Void_backgroundWebp;

function use(redirect, whatExperience, isPageBlacklisted) {
  var libraryPage = {
    TAG: /* StudentLibrary */1,
    _0: undefined,
    _1: undefined
  };
  FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var onClickCollections = function (param) {
    return Curry._1(redirect, /* StudentCollections */1);
  };
  var onClickShop = function (param) {
    return Curry._1(redirect, /* AvatarShop */2);
  };
  var onClickArcade = function (param) {
    return Curry._1(redirect, /* Arcade */11);
  };
  var onClickLibrary = function (param) {
    return Curry._1(redirect, libraryPage);
  };
  var onClickIntrepizine = function (param) {
    return Curry._1(redirect, {
                TAG: /* Intrepizine */13,
                _0: undefined
              });
  };
  var onClickMyWords = function (param) {
    return Curry._1(redirect, /* MyWordsLists */13);
  };
  var onClickBog = function (param) {
    return Curry._1(redirect, /* Bog */5);
  };
  var onClickReports = function (param) {
    return Curry._1(redirect, /* OverviewReport */16);
  };
  var lockCollections = Curry._1(isPageBlacklisted, /* StudentCollections */1);
  var lockShop = Curry._1(isPageBlacklisted, /* AvatarShop */2);
  var lockArcade = Curry._1(isPageBlacklisted, /* Arcade */11);
  var lockLibrary = Curry._1(isPageBlacklisted, libraryPage);
  var lockMyWords = Curry._1(isPageBlacklisted, /* MyWordsLists */13);
  if (whatExperience !== 0) {
    var onClickMissions = function (param) {
      return Curry._1(redirect, /* JourneyMissions */10);
    };
    return [
            onClickMissions,
            onClickCollections,
            onClickShop,
            onClickArcade,
            onClickLibrary,
            onClickIntrepizine,
            onClickMyWords,
            onClickBog,
            onClickReports,
            lockCollections,
            lockShop,
            lockArcade,
            lockLibrary,
            lockMyWords
          ];
  }
  var onClickMissions$1 = function (param) {
    return Curry._1(redirect, {
                TAG: /* SecondaryMissions */0,
                _0: undefined
              });
  };
  return [
          onClickMissions$1,
          onClickCollections,
          onClickShop,
          onClickArcade,
          onClickLibrary,
          onClickIntrepizine,
          onClickMyWords,
          onClickBog,
          onClickReports,
          lockCollections,
          lockShop,
          lockArcade,
          lockLibrary,
          lockMyWords
        ];
}

var MakeRoutes = {
  use: use
};

function getInitial(word) {
  var sanitised = word.trim().toUpperCase();
  if (sanitised.length > 0) {
    return sanitised.substring(0, 1) + ".";
  } else {
    return "";
  }
}

function StudentExperienceWrap(Props) {
  var redirect = Props.redirect;
  var background = Props.background;
  var layout = Props.layout;
  var page = Props.page;
  var children = Props.children;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userName = user.name;
  var userInitials = getInitial(user.givenName) + getInitial(user.familyName);
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience(undefined);
  var match = use(redirect, whatExperience, isPageBlacklisted);
  var lockMyWords = match[13];
  var lockLibrary = match[12];
  var lockArcade = match[11];
  var lockShop = match[10];
  var lockCollections = match[9];
  var onClickMyWords = match[6];
  var onClickIntrepizine = match[5];
  var onClickLibrary = match[4];
  var onClickArcade = match[3];
  var onClickShop = match[2];
  var onClickCollections = match[1];
  var onClickMissions = match[0];
  var showWordManiaLink = Curry._1(hasFlag, "StudentCanAccessWM");
  var showLibraryLink = Curry._1(hasFlag, "StudentCanUseLibrary");
  var showArcade = Curry._1(hasFlag, "StudentHasArcade");
  var showTeacherModeWidget = Curry._1(hasFlag, "StudentIsStudentMode") && !process.env.MOBILE;
  var teacherModeLink = PageTypes$LiteracyplanetClientSxEntry.classicUrl("/student_mode");
  var onClickWordMania = function (param) {
    return MobileUtils$LiteracyplanetClientSxEntry.openWordMania(undefined);
  };
  var onClickLogout = function (param) {
    return MobileUtils$LiteracyplanetClientSxEntry.signOut(undefined);
  };
  var whatMenu = Wrap$LiteracyplanetClientFlags.useMenu(undefined);
  var whatNavBar = Wrap$LiteracyplanetClientFlags.useNavBar(undefined);
  var menu;
  switch (whatMenu) {
    case /* Initials */0 :
        menu = React.createElement(InitialsMenuRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              userName: userName,
              userInitials: userInitials,
              showWordManiaLink: showWordManiaLink,
              showTeacherModeWidget: showTeacherModeWidget,
              teacherModeLink: teacherModeLink,
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout
            });
        break;
    case /* JourneyInitials */1 :
        menu = React.createElement(JourneyInitialsMenu$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              userName: userName,
              userInitials: userInitials,
              showWordManiaLink: showWordManiaLink,
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout
            });
        break;
    case /* Menuless */2 :
        menu = null;
        break;
    
  }
  var isMissions;
  isMissions = typeof page === "number" ? (
      page === /* JourneyMissions */10 ? true : false
    ) : (
      page.TAG === /* SecondaryMissions */0 ? true : false
    );
  var isCollections = page === 1;
  var isLibrary;
  if (typeof page === "number") {
    isLibrary = false;
  } else {
    switch (page.TAG | 0) {
      case /* StudentLibrary */1 :
      case /* Intrepizine */13 :
          isLibrary = true;
          break;
      default:
        isLibrary = false;
    }
  }
  var isArcade = page === 11;
  var isMyWords = page === 13;
  var navBar;
  switch (whatNavBar) {
    case /* Modern */0 :
        navBar = React.createElement(ModernNavBarRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              highlightMissions: isMissions,
              onClickMissions: onClickMissions,
              highlightCollections: isCollections,
              lockCollections: lockCollections,
              onClickCollections: onClickCollections,
              highlightArcade: isArcade,
              showArcade: showArcade,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              highlightLibrary: isLibrary,
              showLibraryLink: showLibraryLink,
              lockLibrary: lockLibrary,
              onClickLibrary: onClickLibrary,
              onClickIntrepizine: onClickIntrepizine,
              highlightMyWords: isMyWords,
              lockMyWords: lockMyWords,
              onClickMyWords: onClickMyWords,
              menu: menu
            });
        break;
    case /* Barless */1 :
        navBar = menu;
        break;
    case /* Bubble */2 :
        navBar = React.createElement(BubbleNavBarRatio$LiteracyplanetClientSxEntry.make, {
              collectionNotificationCount: 0,
              highlightMissions: isMissions,
              onClickMissions: onClickMissions,
              highlightCollections: isCollections,
              lockCollections: lockCollections,
              onClickCollections: onClickCollections,
              highlightArcade: isArcade,
              showArcade: showArcade,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              highlightLibrary: isLibrary,
              showLibraryLink: showLibraryLink,
              lockLibrary: lockLibrary,
              onClickLibrary: onClickLibrary,
              onClickIntrepizine: onClickIntrepizine,
              highlightMyWords: isMyWords,
              lockMyWords: lockMyWords,
              onClickMyWords: onClickMyWords,
              menu: menu
            });
        break;
    case /* Papyrus */3 :
        navBar = React.createElement(ForestNavBar$LiteracyplanetClientSxEntry.make, {
              userName: userName,
              location: "Shabby Hut",
              onClickMissions: onClickMissions,
              onClickIntrepizine: onClickIntrepizine,
              onClickMyWords: onClickMyWords,
              onClickLibrary: onClickLibrary,
              onClickBog: match[7],
              onClickCollections: onClickCollections,
              lockArcade: lockArcade,
              onClickArcade: onClickArcade,
              lockShop: lockShop,
              onClickShop: onClickShop,
              showLibraryLink: showLibraryLink,
              showWordManiaLink: showWordManiaLink,
              onClickReports: match[8],
              onClickWordMania: onClickWordMania,
              onClickLogout: onClickLogout,
              showTeacherModeWidget: showTeacherModeWidget,
              teacherModeLink: teacherModeLink
            });
        break;
    
  }
  var bg;
  switch (background) {
    case /* MainBackground */0 :
        bg = {
          TAG: /* Src */0,
          _0: backgroundSrc
        };
        break;
    case /* IntrepizineBackground */1 :
        bg = {
          TAG: /* TiledSrc */3,
          _0: intrepizineBackgroundSrc
        };
        break;
    case /* VoidBackground */2 :
        bg = {
          TAG: /* Src */0,
          _0: voidBackgroundSrc
        };
        break;
    case /* NoBackground */3 :
        bg = {
          TAG: /* Color */1,
          _0: "FFF"
        };
        break;
    
  }
  if (whatExperience !== 0) {
    switch (layout) {
      case /* AuthWithMenu */0 :
          return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                      background: bg,
                      dark: false,
                      children: null
                    }, navBar, children);
      case /* AuthWithTitleBar */1 :
          return React.createElement(React.Fragment, undefined, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                          background: bg,
                          dark: false,
                          children: children
                        }));
      case /* AuthWithTitleBarAndHeader */2 :
          return React.createElement(React.Fragment, undefined, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                          background: bg,
                          dark: false,
                          children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                                children: children,
                                topMargin: 150
                              })
                        }));
      case /* DarkBackground */3 :
          return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                      background: bg,
                      dark: true,
                      children: children
                    });
      case /* PlainBackground */4 :
      case /* AuthNoMenu */5 :
          break;
      
    }
    return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                background: bg,
                dark: false,
                children: children
              });
  }
  switch (layout) {
    case /* AuthWithMenu */0 :
        return React.createElement(React.Fragment, undefined, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                        background: bg,
                        dark: true,
                        children: children
                      }));
    case /* AuthWithTitleBar */1 :
        return React.createElement(React.Fragment, undefined, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                        background: bg,
                        dark: false,
                        children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                              children: children,
                              topMargin: 100
                            })
                      }));
    case /* AuthWithTitleBarAndHeader */2 :
        return React.createElement(React.Fragment, undefined, navBar, React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                        background: bg,
                        dark: false,
                        children: React.createElement(MarginPanel$LiteracyplanetClientSxEntry.make, {
                              children: children,
                              topMargin: 150
                            })
                      }));
    case /* DarkBackground */3 :
        return React.createElement(BackgroundComponent$LiteracyplanetClientSxEntry.make, {
                    background: bg,
                    dark: true,
                    children: children
                  });
    case /* PlainBackground */4 :
    case /* AuthNoMenu */5 :
        return React.createElement(React.Fragment, undefined, navBar, children);
    
  }
}

function useStudentRedirectToMissions(redirect, isPageBlacklisted) {
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience(undefined);
  return use(redirect, whatExperience, isPageBlacklisted)[0];
}

var make = StudentExperienceWrap;

export {
  backgroundSrc ,
  intrepizineBackgroundSrc ,
  voidBackgroundSrc ,
  MakeRoutes ,
  getInitial ,
  make ,
  useStudentRedirectToMissions ,
  
}
/* backgroundSrc Not a pure module */
