// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import ReactDocumentTitle from "react-document-title";
import * as Error$LiteracyplanetClientErrors from "../../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as OnError$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/OnError.bs.js";
import * as Result2$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Result2.bs.js";
import * as MapResult$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/MapResult.bs.js";
import * as GameTypeData$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/GameTypeData.bs.js";
import * as SpellTestPage$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/v2/SpellTestPage.bs.js";
import * as ToolbarLayout$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ToolbarLayout.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as VideoIcon$LiteracyplanetClientSxActivity from "../VideoIcon.bs.js";
import * as ActivityContainer$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/ActivityContainer.bs.js";
import * as VideoPlayer$LiteracyplanetClientSxActivity from "../VideoPlayer.bs.js";
import * as FlipperStore$LiteracyplanetClientSxActivity from "./FlipperStore.bs.js";
import * as FlipperTypes$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/FlipperTypes.bs.js";
import * as AvatarContext$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/AvatarContext.bs.js";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/SoundProviderEmbedded.bs.js";
import * as FlipperCobraCommon$LiteracyplanetClientSxActivity from "./FlipperCobraCommon.bs.js";
import * as FlipperVideoDispatch$LiteracyplanetClientSxActivity from "./FlipperVideoDispatch.bs.js";
import * as FlipperCobraCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraCallbacks.bs.js";
import * as FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraResultsCallbacks.bs.js";
import * as TupperwareContentQueryParser$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/TupperwareContentQueryParser.bs.js";

function Flipper$PureComponents$Ui$FullScreen(Props) {
  var backgroundColor = Props.backgroundColor;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                background: backgroundColor,
                height: "100%",
                overflowX: "hidden",
                position: "absolute",
                width: "100%"
              }
            }, children);
}

var FullScreen = {
  make: Flipper$PureComponents$Ui$FullScreen
};

function Flipper$PureComponents$Ui$Show(Props) {
  var children = Props.children;
  var visible = Props.visible;
  if (visible) {
    return children;
  } else {
    return null;
  }
}

var Show = {
  make: Flipper$PureComponents$Ui$Show
};

function Flipper$PureComponents$Ui$Visible(Props) {
  var visible = Props.visible;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                display: visible ? "inherit" : "none"
              }
            }, children);
}

var Visible = {
  make: Flipper$PureComponents$Ui$Visible
};

function Flipper$PureComponents$Ui$OffScreen(Props) {
  var visible = Props.visible;
  var backgroundColor = Props.backgroundColor;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                background: backgroundColor,
                height: "100%",
                left: "0px",
                overflow: "hidden",
                position: "absolute",
                top: visible ? "0px" : "-10000px",
                width: "100%"
              }
            }, children);
}

var OffScreen = {
  make: Flipper$PureComponents$Ui$OffScreen
};

var Ui = {
  FullScreen: FullScreen,
  Show: Show,
  Visible: Visible,
  OffScreen: OffScreen
};

function Flipper$PureComponents$ResultsComponent(Props) {
  var loadResults = Props.loadResults;
  var showResults = Props.showResults;
  var resultsProps = Props.resultsProps;
  var resultsDispatch = Props.resultsDispatch;
  if (loadResults) {
    return React.createElement(Flipper$PureComponents$Ui$OffScreen, {
                visible: showResults,
                backgroundColor: "#000000",
                children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                      view: "results",
                      props: resultsProps,
                      onDispatch: resultsDispatch
                    })
              });
  } else {
    return null;
  }
}

var ResultsComponent = {
  make: Flipper$PureComponents$ResultsComponent
};

function Flipper$PureComponents$TupperwareComponent(Props) {
  var title = Props.title;
  var visible = Props.visible;
  var backgroundColor = Props.backgroundColor;
  var results = Props.results;
  var children = Props.children;
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(React.Fragment, undefined, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: backgroundColor,
                        children: React.createElement(Flipper$PureComponents$Ui$Visible, {
                              visible: visible,
                              children: children
                            })
                      }), results)
            });
}

var TupperwareComponent = {
  make: Flipper$PureComponents$TupperwareComponent
};

function Flipper$PureComponents$CobraComponent(Props) {
  var title = Props.title;
  var visible = Props.visible;
  var activityLoaded = Props.activityLoaded;
  var cobraView = Props.cobraView;
  var activityProps = Props.activityProps;
  var activityDispatch = Props.activityDispatch;
  var results = Props.results;
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(React.Fragment, undefined, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: activityLoaded ? "#090E12" : "#FFFFFF",
                        children: React.createElement(Flipper$PureComponents$Ui$Show, {
                              visible: visible,
                              children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                                    view: cobraView,
                                    props: activityProps,
                                    onDispatch: activityDispatch
                                  })
                            })
                      }), results)
            });
}

var CobraComponent = {
  make: Flipper$PureComponents$CobraComponent
};

var PureComponents = {
  Ui: Ui,
  ResultsComponent: ResultsComponent,
  TupperwareComponent: TupperwareComponent,
  CobraComponent: CobraComponent
};

var FlipperCantFindGameById = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.CommonUtil.FlipperCantFindGameById");

function makeCobraResults(state) {
  return {
          currentStudent: {},
          results: {
            percentCorrect: state.percentCorrect,
            chest: "GEM",
            needsToRefreshDivOffsets: state.showResults,
            needsToShowMissionComplete: state.needsToShowMissionComplete
          }
        };
}

var CommonUtil = {
  FlipperCantFindGameById: FlipperCantFindGameById,
  makeCobraResults: makeCobraResults
};

function Flipper$CobraResultsContainer(Props) {
  var resultsDispatch = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var resultsProps = makeCobraResults(state);
  return React.createElement(Flipper$PureComponents$ResultsComponent, {
              loadResults: state.loadResults,
              showResults: state.showResults,
              resultsProps: resultsProps,
              resultsDispatch: resultsDispatch
            });
}

var CobraResultsContainer = {
  make: Flipper$CobraResultsContainer
};

function Flipper$CobraWordGameComponentContainer(Props) {
  var activityProps = Props.activityProps;
  var title = Props.title;
  var cobraActivity = Props.cobraActivity;
  var renderResultsContainer = Props.renderResultsContainer;
  var activityDispatch = FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var avatar = Curry._1(AvatarContext$LiteracyplanetClientLibsActivity.use, undefined);
  var activityProps_currentStudent = {
    avatar: avatar
  };
  var activityProps_questionIndex = state.numComplete;
  var activityProps_numCorrect = state.numCorrect;
  var activityProps_activityMetadata = activityProps.activityMetadata;
  var activityProps_extraVocals = activityProps.extraVocals;
  var activityProps_wordGameContent = activityProps.wordGameContent;
  var activityProps$1 = {
    currentStudent: activityProps_currentStudent,
    questionIndex: activityProps_questionIndex,
    numCorrect: activityProps_numCorrect,
    activityMetadata: activityProps_activityMetadata,
    extraVocals: activityProps_extraVocals,
    wordGameContent: activityProps_wordGameContent
  };
  var cobraView = cobraActivity.cobraView;
  if (state.initialised) {
    return React.createElement(Flipper$PureComponents$CobraComponent, {
                title: title,
                visible: !state.showResults,
                activityLoaded: state.activityLoaded,
                cobraView: cobraView,
                activityProps: activityProps$1,
                activityDispatch: activityDispatch,
                results: Curry._1(renderResultsContainer, undefined)
              });
  } else {
    return null;
  }
}

var CobraWordGameComponentContainer = {
  make: Flipper$CobraWordGameComponentContainer
};

function mergeProps(props, extraVocals, activityMetadata) {
  switch (props.TAG | 0) {
    case /* WordListData */0 :
        var activityProps = props._0;
        return {
                TAG: /* WordListData */0,
                _0: {
                  currentStudent: activityProps.currentStudent,
                  questionIndex: activityProps.questionIndex,
                  numCorrect: activityProps.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps.wordGameContent
                }
              };
    case /* SoundTrainData */1 :
        var activityProps$1 = props._0;
        return {
                TAG: /* SoundTrainData */1,
                _0: {
                  currentStudent: activityProps$1.currentStudent,
                  questionIndex: activityProps$1.questionIndex,
                  numCorrect: activityProps$1.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$1.wordGameContent
                }
              };
    case /* WhackAMunkData */2 :
        var activityProps$2 = props._0;
        return {
                TAG: /* WhackAMunkData */2,
                _0: {
                  currentStudent: activityProps$2.currentStudent,
                  questionIndex: activityProps$2.questionIndex,
                  numCorrect: activityProps$2.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$2.wordGameContent
                }
              };
    case /* MonsterChefData */3 :
        var activityProps$3 = props._0;
        return {
                TAG: /* MonsterChefData */3,
                _0: {
                  currentStudent: activityProps$3.currentStudent,
                  questionIndex: activityProps$3.questionIndex,
                  numCorrect: activityProps$3.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$3.wordGameContent
                }
              };
    case /* InernalStaticData */4 :
        var activityProps$4 = props._0;
        return {
                TAG: /* InernalStaticData */4,
                _0: {
                  currentStudent: activityProps$4.currentStudent,
                  questionIndex: activityProps$4.questionIndex,
                  numCorrect: activityProps$4.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$4.wordGameContent
                }
              };
    case /* UnsafeData */5 :
        var activityProps$5 = props._0;
        return {
                TAG: /* UnsafeData */5,
                _0: {
                  currentStudent: activityProps$5.currentStudent,
                  questionIndex: activityProps$5.questionIndex,
                  numCorrect: activityProps$5.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$5.wordGameContent
                }
              };
    
  }
}

function mergeCommonProps(cobraActivity, localeCtx, title, props) {
  return mergeProps(props, FlipperCobraCommon$LiteracyplanetClientSxActivity.GameInstructions.parse(cobraActivity, localeCtx), {
              title: title
            });
}

function Flipper$Cobra$RenderComponent(Props) {
  var props = Props.props;
  var cobraActivity = Props.cobraActivity;
  var title = Props.title;
  var hasResultsScreen = Props.hasResultsScreen;
  switch (props.TAG | 0) {
    case /* WordListData */0 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case /* SoundTrainData */1 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case /* WhackAMunkData */2 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case /* MonsterChefData */3 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case /* InernalStaticData */4 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    case /* UnsafeData */5 :
        return React.createElement(Flipper$CobraWordGameComponentContainer, {
                    activityProps: props._0,
                    title: title,
                    cobraActivity: cobraActivity,
                    renderResultsContainer: (function (param) {
                        if (hasResultsScreen) {
                          return React.createElement(Flipper$CobraResultsContainer, {});
                        } else {
                          return null;
                        }
                      })
                  });
    
  }
}

var RenderComponent = {
  make: Flipper$Cobra$RenderComponent
};

function Flipper$Cobra$Render(Props) {
  var props = Props.props;
  var cobraActivity = Props.cobraActivity;
  var title = Props.title;
  var hasResultsScreenOpt = Props.hasResultsScreen;
  var hasResultsScreen = hasResultsScreenOpt !== undefined ? hasResultsScreenOpt : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Belt_Result.flatMap(props, (function (param) {
                      return FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.validate(cobraActivity, param);
                    })),
              onOk: (function (props) {
                  return React.createElement(Flipper$Cobra$RenderComponent, {
                              props: props,
                              cobraActivity: cobraActivity,
                              title: title,
                              hasResultsScreen: hasResultsScreen
                            });
                })
            });
}

var Render = {
  make: Flipper$Cobra$Render
};

var Cobra = {
  mergeProps: mergeProps,
  mergeCommonProps: mergeCommonProps,
  RenderComponent: RenderComponent,
  Render: Render
};

function Flipper$TupperwareContainer(Props) {
  var data = Props.data;
  var title = Props.title;
  var results = Props.results;
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var visible = !state.showResults;
  var backgroundColor = state.backgroundColor;
  var c = React.createElement(Flipper$PureComponents$TupperwareComponent, {
        title: title,
        visible: visible,
        backgroundColor: backgroundColor,
        results: results,
        children: React.createElement(ActivityContainer$LiteracyplanetTupperware.make, {
              data: data
            })
      });
  return React.createElement(SoundProviderEmbedded$LiteracyplanetClientFlags.make, {
              children: c
            });
}

var TupperwareContainer = {
  make: Flipper$TupperwareContainer
};

var FlipperTupperwareDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Tupperware.FlipperTupperwareDataNotValid");

function parse(content) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.getTupperwareData(content), {
              RE_EXN_ID: FlipperTupperwareDataNotValid
            });
}

function Flipper$Tupperware$Render(Props) {
  var hasResultsScreenOpt = Props.hasResultsScreen;
  var content = Props.content;
  var title = Props.title;
  var hasResultsScreen = hasResultsScreenOpt !== undefined ? hasResultsScreenOpt : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: parse(content),
              onOk: (function (param) {
                  return React.createElement(Flipper$TupperwareContainer, {
                              data: param[0],
                              title: title,
                              results: hasResultsScreen ? React.createElement(Flipper$CobraResultsContainer, {}) : null
                            });
                })
            });
}

var Render$1 = {
  make: Flipper$Tupperware$Render
};

var Tupperware = {
  FlipperTupperwareDataNotValid: FlipperTupperwareDataNotValid,
  parse: parse,
  isSkilfulLayout: TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.isSkilfulLayout,
  Render: Render$1
};

var pageStyles = {
  backgroundColor: "000",
  color: "0C9CE5",
  activeColor: "723C8D",
  hoverColor: "0A88E0",
  cover: false
};

function Flipper$VideoComponentContainer(Props) {
  var source = Props.source;
  var title = Props.title;
  var callbacks = FlipperVideoDispatch$LiteracyplanetClientSxActivity.Context.use(undefined);
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(ToolbarLayout$LiteracyplanetClientFlags.make, {
                    icon: React.createElement(VideoIcon$LiteracyplanetClientSxActivity.make, {}),
                    title: title,
                    exitTo: callbacks.onToolbarExit,
                    pageStyles: pageStyles,
                    children: React.createElement("div", {
                          style: {
                            padding: "1rem"
                          }
                        }, React.createElement(VideoPlayer$LiteracyplanetClientSxActivity.make, {
                              source: source,
                              callbacks: callbacks
                            }))
                  })
            });
}

var VideoComponentContainer = {
  pageStyles: pageStyles,
  make: Flipper$VideoComponentContainer
};

var FlipperVideoDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Video.FlipperVideoDataNotValid");

function Flipper$Video$Render(Props) {
  var content = Props.content;
  var title = Props.title;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "VideoData" ? Belt_Option.map(Belt_Option.flatMap(content.VAL.video, (function (v) {
                                return Belt_Array.get(v.sources, 0);
                              })), (function (v) {
                            return v.source;
                          })) : undefined, {
                    RE_EXN_ID: FlipperVideoDataNotValid
                  }),
              onOk: (function (source) {
                  return React.createElement(Flipper$VideoComponentContainer, {
                              source: source,
                              title: title
                            });
                })
            });
}

var Render$2 = {
  make: Flipper$Video$Render
};

var Video = {
  FlipperVideoDataNotValid: FlipperVideoDataNotValid,
  Render: Render$2
};

function Flipper$SpellTestComponentContainer(Props) {
  var resultsContainer = Props.resultsContainer;
  var list = Props.list;
  var title = Props.title;
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var visible = !state.showResults;
  var backgroundColor = state.backgroundColor;
  return React.createElement(Flipper$PureComponents$TupperwareComponent, {
              title: title,
              visible: visible,
              backgroundColor: backgroundColor,
              results: resultsContainer,
              children: React.createElement(SpellTestPage$LiteracyplanetTupperware.make, {
                    list: list
                  })
            });
}

var SpellTestComponentContainer = {
  make: Flipper$SpellTestComponentContainer
};

var FlipperSpellTestDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.SpellTest.FlipperSpellTestDataNotValid");

function Flipper$SpellTest$Render(Props) {
  var hasResultsScreenOpt = Props.hasResultsScreen;
  var content = Props.content;
  var title = Props.title;
  var hasResultsScreen = hasResultsScreenOpt !== undefined ? hasResultsScreenOpt : true;
  return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
              result: Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "WordGameData" ? content.VAL.list : undefined, {
                    RE_EXN_ID: FlipperSpellTestDataNotValid
                  }),
              onOk: (function (list) {
                  return React.createElement(Flipper$SpellTestComponentContainer, {
                              resultsContainer: hasResultsScreen ? React.createElement(Flipper$CobraResultsContainer, {}) : null,
                              list: list,
                              title: title
                            });
                })
            });
}

var Render$3 = {
  make: Flipper$SpellTest$Render
};

var SpellTest = {
  FlipperSpellTestDataNotValid: FlipperSpellTestDataNotValid,
  Render: Render$3
};

function Flipper$CallError(Props) {
  var e = Props.e;
  var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
  var onSetError = match.onSetError;
  React.useEffect((function () {
          console.log(e);
          Curry._1(onSetError, {
                TAG: /* Exception */3,
                _0: e
              });
          
        }), []);
  return null;
}

var CallError = {
  make: Flipper$CallError
};

function Flipper$Container(Props) {
  var onErrorOpt = Props.onError;
  var getActivity = Props.getActivity;
  var getGameId = Props.getGameId;
  var queryState = Props.queryState;
  var onOk = Props.onOk;
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (e) {
        return React.createElement(Flipper$CallError, {
                    e: e
                  });
      });
  var tmp;
  if (queryState) {
    var query = queryState._0;
    tmp = React.createElement(MapResult$LiteracyplanetClientFlags.make, {
          result: Curry._1(getActivity, query),
          onOk: (function (content) {
              return React.createElement(MapResult$LiteracyplanetClientFlags.make, {
                          result: Belt_Result.flatMap(Curry._1(getGameId, query), (function (game) {
                                  return Result2$LiteracyplanetClientFlags.Opt.toResult(GameTypeData$LiteracyplanetClientFlags.findByGameId(game), {
                                              RE_EXN_ID: FlipperCantFindGameById
                                            });
                                })),
                          onOk: (function (game) {
                              return Curry._3(onOk, game, content, query);
                            })
                        });
            })
        });
  } else {
    tmp = null;
  }
  return React.createElement(OnError$LiteracyplanetClientFlags.Provider.make, {
              value: onError,
              children: tmp
            });
}

var Container = {
  make: Flipper$Container
};

export {
  PureComponents ,
  CommonUtil ,
  CobraResultsContainer ,
  CobraWordGameComponentContainer ,
  Cobra ,
  TupperwareContainer ,
  Tupperware ,
  VideoComponentContainer ,
  Video ,
  SpellTestComponentContainer ,
  SpellTest ,
  CallError ,
  Container ,
  
}
/* react Not a pure module */
