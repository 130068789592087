// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../node_modules/rescript/lib/es6/caml_exceptions.js";

function Make(S) {
  var context = React.createContext(undefined);
  var make = context.Provider;
  var P = {
    make: make
  };
  var ContextMaker$Make$Provider = function (Props) {
    var value = Props.value;
    var children = Props.children;
    return React.createElement(make, {
                value: Caml_option.some(value),
                children: children
              });
  };
  var Provider = {
    make: ContextMaker$Make$Provider
  };
  var ContextMakerProviderNotFound = /* @__PURE__ */Caml_exceptions.create("ContextMaker-LiteracyplanetClientFlags.Make(S).ContextMakerProviderNotFound");
  var use = function (param) {
    var c = React.useContext(context);
    try {
      return Belt_Option.getExn(c);
    }
    catch (exn){
      throw {
            RE_EXN_ID: ContextMakerProviderNotFound,
            _1: S.name,
            Error: new Error()
          };
    }
  };
  return {
          initialContext: undefined,
          context: context,
          P: P,
          Provider: Provider,
          ContextMakerProviderNotFound: ContextMakerProviderNotFound,
          use: use
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
