// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Wrap$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/Wrap.bs.js";
import * as Error$LiteracyplanetClientErrors from "../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as Lazy$LiteracyplanetClientSxEntry from "./Lazy.bs.js";
import * as Option2$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/Option2.bs.js";
import * as NoMatch$LiteracyplanetClientSxEntry from "./NoMatch.bs.js";
import * as HasuraTypes$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/HasuraTypes.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as RouteContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/RouteContext.bs.js";
import * as InMemoryStore$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/InMemoryStore.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/LocaleContext.bs.js";
import * as MobileUtils$LiteracyplanetClientSxEntry from "./MobileUtils.bs.js";
import * as ProRequired$LiteracyplanetClientSxEntry from "./ProRequired.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as SeenBeforeQuery$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SeenBeforeQuery.bs.js";
import * as StoryverseIntro$LiteracyplanetClientSxEntry from "./book_of_goings/StoryverseIntro.bs.js";
import * as ChecksLobbyQuery$LiteracyplanetClientSxEntry from "./checks/ChecksLobbyQuery.bs.js";
import * as SeenBeforeMutation$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SeenBeforeMutation.bs.js";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.bs.js";
import * as BogTabSerialisation$LiteracyplanetClientSxEntry from "./book_of_goings/BogTabSerialisation.bs.js";
import * as JourneyMissionsQuery$LiteracyplanetClientSxEntry from "./JourneyMissionsQuery.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.bs.js";
import * as JourneyActivitiesQuery$LiteracyplanetClientSxEntry from "./JourneyActivitiesQuery.bs.js";
import * as JourneyActivitiesScreen$LiteracyplanetClientSxEntry from "./JourneyActivitiesScreen.bs.js";
import * as DeepLinkActivitiesScreen$LiteracyplanetClientSxEntry from "./DeepLinkActivitiesScreen.bs.js";
import * as FlipperChecksActivityLauncher$LiteracyplanetClientSxActivity from "../../../../node_modules/@literacyplanet/client-sx-activity/src/flipper/FlipperChecksActivityLauncher.bs.js";

var videoFolder = "https://vod-watchfolder-literacyplanet.s3-ap-southeast-2.amazonaws.com";

function vodFolder(video) {
  return "" + videoFolder + "/" + video + "/Default/HLS/" + video + ".m3u8";
}

function devPrefix(isDev, name) {
  return (
          isDev ? "dev_" : ""
        ) + name;
}

function source(isDev, videoName) {
  return vodFolder(devPrefix(isDev, videoName));
}

function Page$JourneyIntroCheck(Props) {
  var redirect = Props.redirect;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var redirectJourney = function (param) {
    return Curry._1(redirect, /* Journey */6);
  };
  var redirectJourneyMissions = function (param) {
    return Curry._1(redirect, /* JourneyMissions */10);
  };
  var seenBefore = SeenBeforeQuery$LiteracyplanetClientFlags.useCurrentUser(undefined);
  React.useEffect((function () {
          if (Curry._1(hasFlag, "StudentHasJourneyIntro")) {
            SeenBeforeQuery$LiteracyplanetClientFlags.hasSeenType(seenBefore, "JOURNEY_INTRO", redirectJourneyMissions, redirectJourney);
          } else {
            Curry._1(redirect, /* JourneyMissions */10);
          }
          
        }), [seenBefore]);
  return null;
}

var JourneyIntroCheck = {
  make: Page$JourneyIntroCheck
};

function Page$StoryverseIntroCheck(Props) {
  var redirect = Props.redirect;
  FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var redirectStoryverseIntro = function (param) {
    return Curry._1(redirect, /* StoryverseIntro */4);
  };
  var redirectChecksLobby = function (param) {
    return Curry._1(redirect, /* ChecksLobby */14);
  };
  var seenBefore = SeenBeforeQuery$LiteracyplanetClientFlags.useCurrentUser(undefined);
  React.useEffect((function () {
          SeenBeforeQuery$LiteracyplanetClientFlags.hasSeenType(seenBefore, "STORYVERSE_INTRO", redirectChecksLobby, redirectStoryverseIntro);
          
        }), [seenBefore]);
  return null;
}

var StoryverseIntroCheck = {
  make: Page$StoryverseIntroCheck
};

function Page$StudentExperience(Props) {
  var redirect = Props.redirect;
  var whatExperience = Wrap$LiteracyplanetClientFlags.useStudentExperience(undefined);
  React.useEffect(function () {
        if (whatExperience === /* Secondary */0) {
          Curry._1(redirect, /* ChecksLobby */14);
        }
        
      });
  switch (whatExperience) {
    case /* Secondary */0 :
        return null;
    case /* Journey */1 :
        return React.createElement(Page$JourneyIntroCheck, {
                    redirect: redirect
                  });
    case /* Storyverse */2 :
        return React.createElement(Page$StoryverseIntroCheck, {
                    redirect: redirect
                  });
    
  }
}

var StudentExperience = {
  make: Page$StudentExperience
};

function Page$StudentCollections(Props) {
  var redirect = Props.redirect;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var isItemBlacklisted = Props.isItemBlacklisted;
  var onToggleProModal = Props.onToggleProModal;
  var onToggleLockedModal = Props.onToggleLockedModal;
  FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var onClickShop = function (param) {
    return Curry._1(redirect, /* AvatarShop */2);
  };
  var onClickArcade = function (param) {
    return Curry._1(redirect, /* Arcade */11);
  };
  var isShopLocked = Curry._1(isPageBlacklisted, /* AvatarShop */2);
  var isArcadeLocked = Curry._1(isPageBlacklisted, /* Arcade */11);
  var isMiniGameLocked = Curry._1(isItemBlacklisted, /* MiniGame */0);
  var isProRequired = ProRequired$LiteracyplanetClientSxEntry.use(undefined);
  var key = "locked-" + isShopLocked;
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.CollectionsContainer.make, Lazy$LiteracyplanetClientSxEntry.CollectionsContainer.makeProps(userId, onClickShop, onClickArcade, isShopLocked, isArcadeLocked, isMiniGameLocked, onToggleProModal, onToggleLockedModal, Curry._1(isProRequired, /* MiniGame */0), key, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var StudentCollections = {
  make: Page$StudentCollections
};

function Page$StudentLibrary(Props) {
  var groupIndex = Props.groupIndex;
  var bookId = Props.bookId;
  var redirect = Props.redirect;
  var defaultGroupIndex = Belt_Option.mapWithDefault(groupIndex, 0, Caml_format.caml_int_of_string);
  var onChangeBookId = function (bookId) {
    return Curry._1(redirect, {
                TAG: /* StudentLibrary */1,
                _0: String(defaultGroupIndex),
                _1: bookId
              });
  };
  var onChangeGroupIndex = function (groupIndex) {
    return Curry._1(redirect, {
                TAG: /* StudentLibrary */1,
                _0: String(groupIndex),
                _1: undefined
              });
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.Library.make, Lazy$LiteracyplanetClientSxEntry.Library.makeProps(bookId, defaultGroupIndex, onChangeBookId, onChangeGroupIndex, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var StudentLibrary = {
  make: Page$StudentLibrary
};

function Page$SecondaryMissions(Props) {
  var redirect = Props.redirect;
  var value_onClickActivity = function (studentActivityId, locale, accent) {
    return Curry._1(redirect, {
                TAG: /* StudentActivity */2,
                _0: locale,
                _1: accent,
                _2: studentActivityId
              });
  };
  var value_onClickMissionFilter = function (_filter) {
    return Curry._1(redirect, {
                TAG: /* SecondaryMissions */0,
                _0: undefined
              });
  };
  var value = {
    onClickActivity: value_onClickActivity,
    onClickMissionFilter: value_onClickMissionFilter
  };
  return React.createElement(RouteContext$LiteracyplanetClientFlags.Missions.make, RouteContext$LiteracyplanetClientFlags.Missions.makeProps(value, React.createElement(React.Suspense, {
                      children: React.createElement(Lazy$LiteracyplanetClientSxEntry.SecondaryMissions.make, Lazy$LiteracyplanetClientSxEntry.SecondaryMissions.makeProps(undefined, undefined)),
                      fallback: React.createElement("div", undefined)
                    }), undefined));
}

var SecondaryMissions = {
  make: Page$SecondaryMissions
};

function Page$NoMatch(Props) {
  return React.createElement(NoMatch$LiteracyplanetClientSxEntry.make, {});
}

var NoMatch = {
  make: Page$NoMatch
};

function Page$Activity(Props) {
  var onGoToMissions = Props.onGoToMissions;
  var onActivityNotFound = Props.onActivityNotFound;
  var onToggleLocked = Props.onToggleLocked;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var redirect = Props.redirect;
  var onExit = Props.onExit;
  var locale = Props.locale;
  var accent = Props.accent;
  var studentActivityId = Props.studentActivityId;
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var onMaybeGotoCollections = function (callback) {
    if (Curry._1(isPageBlacklisted, /* StudentCollections */1)) {
      return Curry._1(onToggleLocked, undefined);
    } else {
      return Curry._1(callback, Curry._1(redirect, /* StudentCollections */1));
    }
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.FlipperActivity.make, Lazy$LiteracyplanetClientSxEntry.FlipperActivity.makeProps(user.userId, onMaybeGotoCollections, onActivityNotFound, onGoToMissions, onExit, studentActivityId, accent, locale, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var Activity = {
  make: Page$Activity
};

function Page$StudentActivity(Props) {
  var redirect = Props.redirect;
  var locale = Props.locale;
  var accent = Props.accent;
  var studentActivityId = Props.studentActivityId;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var onToggleLocked = Props.onToggleLocked;
  var onGoToMissions = Props.onGoToMissions;
  return React.createElement(Page$Activity, {
              onGoToMissions: onGoToMissions,
              onActivityNotFound: onGoToMissions,
              onToggleLocked: onToggleLocked,
              isPageBlacklisted: isPageBlacklisted,
              redirect: redirect,
              onExit: onGoToMissions,
              locale: locale,
              accent: accent,
              studentActivityId: studentActivityId
            });
}

var StudentActivity = {
  make: Page$StudentActivity
};

function Page$PlayAnyActivity(Props) {
  var state = Props.state;
  var goBack = Props.goBack;
  var locale = Props.locale;
  var accent = Props.accent;
  var contentId = Props.contentId;
  var contentVersion = Props.contentVersion;
  var contentKind = Props.contentKind;
  var kind = Props.kind;
  FlagsContext$LiteracyplanetClientFlags.use(undefined);
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.FlipperPlayAnyActivity.make, Lazy$LiteracyplanetClientSxEntry.FlipperPlayAnyActivity.makeProps(contentId, contentVersion, contentKind, kind, locale, accent, Js_dict.get(state, "title"), (function (param) {
                          return Curry._1(goBack, undefined);
                        }), (function (param) {
                          return Curry._1(goBack, undefined);
                        }), (function (callback) {
                          return Curry._1(callback, undefined);
                        }), undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var PlayAnyActivity = {
  make: Page$PlayAnyActivity
};

function Page$AvatarShop(Props) {
  var onGoToMissions = Props.onGoToMissions;
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var isProRequired = ProRequired$LiteracyplanetClientSxEntry.use(undefined);
  var isProAccessLocked = Curry._1(isProRequired, /* AvatarShop */1);
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.AvatarShop.make, Lazy$LiteracyplanetClientSxEntry.AvatarShop.makeProps(userId, isProAccessLocked, onGoToMissions, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var AvatarShop = {
  make: Page$AvatarShop
};

function Page$StoryverseIntro(Props) {
  var redirect = Props.redirect;
  var markSeenBefore = SeenBeforeMutation$LiteracyplanetClientFlags.useSeenBeforeWithUser("STORYVERSE_INTRO");
  var onExit = function (param) {
    Curry._1(markSeenBefore, undefined);
    return Curry._1(redirect, /* ChecksLobby */14);
  };
  return React.createElement(StoryverseIntro$LiteracyplanetClientSxEntry.make, {
              onExit: onExit
            });
}

var StoryverseIntro = {
  make: Page$StoryverseIntro
};

function Page$Bog(Props) {
  var redirect = Props.redirect;
  var state = Props.state;
  var redirectWithState = Props.redirectWithState;
  var onChangeNavigation = function (nav) {
    state["nav"] = BogTabSerialisation$LiteracyplanetClientSxEntry.encode(nav);
    return Curry._2(redirectWithState, /* Bog */5, state);
  };
  var onExit = function (param) {
    return Curry._1(redirect, /* JourneyMissions */10);
  };
  var nav = Belt_Option.mapWithDefault(Js_dict.get(state, "nav"), /* BogDefault */0, BogTabSerialisation$LiteracyplanetClientSxEntry.decode);
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.BogContainer.make, Lazy$LiteracyplanetClientSxEntry.BogContainer.makeProps(onExit, onChangeNavigation, nav, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var Bog = {
  make: Page$Bog
};

function Page$JourneyMissions(Props) {
  var redirect = Props.redirect;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match[1];
  LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var missionStates = JourneyMissionsQuery$LiteracyplanetClientSxEntry.useCurrentUser(undefined);
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use(undefined);
  var onSetError = match$1.onSetError;
  JourneyActivitiesQuery$LiteracyplanetClientSxEntry.setReferrerOnLoad("mission");
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var showWordManiaLink = Curry._1(hasFlag, "StudentCanAccessWM");
  if (!missionStates) {
    return null;
  }
  var onDispatch = {
    onJourneyMissionSelected: (function (missionId) {
        return Curry._1(redirect, {
                    TAG: /* JourneyActivities */4,
                    _0: missionId
                  });
      }),
    onPreloaderReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onJourneyReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onFailed: (function (err) {
        return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(/* JourneyMissionsError */0, err));
      }),
    onGoToWordMania: showWordManiaLink ? (function (param) {
          return MobileUtils$LiteracyplanetClientSxEntry.openWordMania(undefined);
        }) : undefined
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "jms",
                  props: missionStates._0,
                  onDispatch: onDispatch
                }));
}

var JourneyMissions = {
  make: Page$JourneyMissions
};

function Page$JourneyActivities(Props) {
  var redirect = Props.redirect;
  var studentMissionId = Props.studentMissionId;
  return React.createElement(JourneyActivitiesScreen$LiteracyplanetClientSxEntry.make, {
              redirect: redirect,
              studentMissionId: Caml_format.caml_int_of_string(studentMissionId)
            });
}

var JourneyActivities = {
  make: Page$JourneyActivities
};

function Page$JourneyActivity(Props) {
  var redirect = Props.redirect;
  var locale = Props.locale;
  var accent = Props.accent;
  var studentMissionId = Props.studentMissionId;
  var studentActivityId = Props.studentActivityId;
  var isAssessment = Props.isAssessment;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var onToggleLocked = Props.onToggleLocked;
  var setReferrer = JourneyActivitiesQuery$LiteracyplanetClientSxEntry.referrerSetter(undefined);
  var onGoToMissions = function (param) {
    Curry._1(setReferrer, "activityComplete");
    if (isAssessment) {
      return Curry._1(redirect, /* JourneyMissions */10);
    } else {
      return Curry._1(redirect, {
                  TAG: /* JourneyActivities */4,
                  _0: studentMissionId
                });
    }
  };
  var onExit = function (param) {
    Curry._1(setReferrer, "activityAbort");
    return Curry._1(redirect, {
                TAG: /* JourneyActivities */4,
                _0: studentMissionId
              });
  };
  return React.createElement(Page$Activity, {
              onGoToMissions: onGoToMissions,
              onActivityNotFound: onGoToMissions,
              onToggleLocked: onToggleLocked,
              isPageBlacklisted: isPageBlacklisted,
              redirect: redirect,
              onExit: onExit,
              locale: locale,
              accent: accent,
              studentActivityId: studentActivityId
            });
}

var JourneyActivity = {
  make: Page$JourneyActivity
};

function Page$Arcade(Props) {
  var redirect = Props.redirect;
  var onClickGame = function (gameName) {
    return Curry._1(redirect, {
                TAG: /* ArcadeGame */7,
                _0: gameName
              });
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.Arcade.make, Lazy$LiteracyplanetClientSxEntry.Arcade.makeProps(onClickGame, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var Arcade = {
  make: Page$Arcade
};

function Page$ArcadeGame(Props) {
  var redirect = Props.redirect;
  var gameKind = Props.gameKind;
  var api = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var locale = api.localeString;
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var onExit = function (param) {
    return Curry._1(redirect, /* Arcade */11);
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.ArcadeGame.make, Lazy$LiteracyplanetClientSxEntry.ArcadeGame.makeProps(onExit, locale, gameKind, userId, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var ArcadeGame = {
  make: Page$ArcadeGame
};

function Page$WordManiaGame(Props) {
  var redirect = Props.redirect;
  var onExit = function (param) {
    return Curry._1(redirect, /* Arcade */11);
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.WordManiaGame.make, Lazy$LiteracyplanetClientSxEntry.WordManiaGame.makeProps(onExit, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var WordManiaGame = {
  make: Page$WordManiaGame
};

function Page$MyWordsLists(Props) {
  var redirect = Props.redirect;
  var replaceWithState = Props.replaceWithState;
  var state = Props.state;
  var onNewList = function (param) {
    return Curry._1(redirect, {
                TAG: /* MyWordsNew */10,
                _0: Uuid.v4()
              });
  };
  var onEditList = function (id) {
    return Curry._1(redirect, {
                TAG: /* MyWordsAssign */9,
                _0: id
              });
  };
  var onSetPage = function (page) {
    state["page"] = String(page);
    return Curry._2(replaceWithState, /* MyWordsLists */13, state);
  };
  var page = Belt_Option.mapWithDefault(Js_dict.get(state, "page"), 1, Caml_format.caml_int_of_string);
  var searchName = Js_dict.get(state, "searchName");
  var onSearchName = function (name) {
    if (name === "") {
      Js_dict.unsafeDeleteKey(state, "searchName");
    } else {
      state["searchName"] = name;
    }
    state["page"] = "1";
    return Curry._2(replaceWithState, /* MyWordsLists */13, state);
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsIndexContainer.make, Lazy$LiteracyplanetClientSxEntry.MyWordsIndexContainer.makeProps(onNewList, onEditList, onSetPage, page, searchName, onSearchName, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var MyWordsLists = {
  make: Page$MyWordsLists
};

function Page$MyWordsAssign(Props) {
  var redirect = Props.redirect;
  var id = Props.id;
  var onBack = function (param) {
    return Curry._1(redirect, /* MyWordsLists */13);
  };
  var onEditList = function (param) {
    return Curry._1(redirect, {
                TAG: /* MyWordsEdit */11,
                _0: id
              });
  };
  var onPlayActivity = function (kind) {
    return Curry._1(redirect, {
                TAG: /* MyWordsActivity */12,
                _0: id,
                _1: kind
              });
  };
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsAssignContainer.make, Lazy$LiteracyplanetClientSxEntry.MyWordsAssignContainer.makeProps(onBack, onEditList, onPlayActivity, id, userId, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var MyWordsAssign = {
  make: Page$MyWordsAssign
};

function Page$MyWordsEdit(Props) {
  var id = Props.id;
  var redirect = Props.redirect;
  var onBack = function (param) {
    return Curry._1(redirect, {
                TAG: /* MyWordsAssign */9,
                _0: id
              });
  };
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.make, Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.makeProps(onBack, onBack, id, userId, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var MyWordsEdit = {
  make: Page$MyWordsEdit
};

function Page$MyWordsNew(Props) {
  var id = Props.id;
  var redirect = Props.redirect;
  var onBack = function (param) {
    return Curry._1(redirect, /* MyWordsLists */13);
  };
  var onSave = function (param) {
    return Curry._1(redirect, {
                TAG: /* MyWordsAssign */9,
                _0: id
              });
  };
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.make, Lazy$LiteracyplanetClientSxEntry.MyWordsEditContainer.makeProps(onBack, onSave, id, userId, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var MyWordsNew = {
  make: Page$MyWordsNew
};

function Page$MyWordsActivity(Props) {
  var id = Props.id;
  var gameId = Props.gameId;
  var redirect = Props.redirect;
  var onBack = function (param) {
    return Curry._1(redirect, {
                TAG: /* MyWordsAssign */9,
                _0: id
              });
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.MyWordsActivityContainer.make, Lazy$LiteracyplanetClientSxEntry.MyWordsActivityContainer.makeProps(gameId, onBack, id, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var MyWordsActivity = {
  make: Page$MyWordsActivity
};

function Page$ChecksLobby$LcLobby(Props) {
  var redirectChecksActivity = Props.redirectChecksActivity;
  var res = Props.res;
  var currentCheck = Props.currentCheck;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match[1];
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use(undefined);
  var onSetError = match$1.onSetError;
  var onDispatch = {
    onJourneyActivitySelected: redirectChecksActivity,
    onExit: (function (param) {
        
      }),
    onPreloaderReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onJourneyReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onFailed: (function (err) {
        return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(/* JourneyActivitiesError */1, err));
      }),
    onLogOut: (function (param) {
        return MobileUtils$LiteracyplanetClientSxEntry.signOut(undefined);
      })
  };
  var makeProps = function (response, currentCheck) {
    return Belt_Option.map(response.currentStudent, (function (s) {
                  return {
                          currentStudent: JourneyCurrentStudent$LiteracyplanetClientSxEntry.makeCurrentStudent(s),
                          activities: Belt_Array.map(response.checks, (function (check) {
                                  return {
                                          id: check.activityId,
                                          kind: check.gameId,
                                          learningMode: {
                                            kind: check.strand,
                                            label: check.strand
                                          },
                                          complete: check.activityComplete,
                                          bestScore: undefined,
                                          inProgress: false,
                                          locked: Caml_obj.caml_notequal(currentCheck.activityId, check.activityId),
                                          assessment: false
                                        };
                                })),
                          mission: {
                            title: "Literacy Check",
                            chest: "GEM"
                          },
                          referrer: "",
                          isLiteracyCheck: true
                        };
                }));
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "jas",
                  props: makeProps(res, currentCheck),
                  onDispatch: onDispatch
                }));
}

var LcLobby = {
  make: Page$ChecksLobby$LcLobby
};

function Page$ChecksLobby$LcAssignmentComplete(Props) {
  var redirectJourneyMissions = Props.redirectJourneyMissions;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match[1];
  var onDispatch = {
    onResultReady: (function (param) {
        
      }),
    onExit: redirectJourneyMissions,
    onPreloaderReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      })
  };
  return React.createElement("div", {
              style: match[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "literacyCheckComplete",
                  props: {
                    results: {
                      title: "Literacy Check"
                    }
                  },
                  onDispatch: onDispatch
                }));
}

var LcAssignmentComplete = {
  make: Page$ChecksLobby$LcAssignmentComplete
};

function Page$ChecksLobby$RedirectComponent(Props) {
  var dest = Props.dest;
  React.useEffect((function () {
          Curry._1(dest, undefined);
          
        }), []);
  return null;
}

var RedirectComponent = {
  make: Page$ChecksLobby$RedirectComponent
};

function Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete(Props) {
  var checks = Props.checks;
  var dest = Props.dest;
  return React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(Belt_Array.reduce(checks, undefined, (function (acc, c) {
                        return Option2$LiteracyplanetClientFlags.combine(c.assignationId, Belt_Option.map(c.studentActivityAttemptId, HasuraTypes$LiteracyplanetClientFlags.decode));
                      })), null, (function (param) {
                    return React.createElement(FlipperChecksActivityLauncher$LiteracyplanetClientSxActivity.MarkCompleteWhenAllComplete.make, {
                                assignationId: param[0],
                                studentActivityAttemptId: param[1]
                              });
                  })), React.createElement(Page$ChecksLobby$RedirectComponent, {
                  dest: dest
                }));
}

var MarkAssignationCompleteWhenAllActivitiesAreComplete = {
  make: Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete
};

function Page$ChecksLobby(Props) {
  var redirect = Props.redirect;
  var onGoToMissions = Props.onGoToMissions;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var hasLC = Curry._1(hasFlag, "StudentHasLiteracyCheck");
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter(undefined);
  var redirectJourneyMissions = function (param) {
    Curry._2(setInMemory, "ranLcActivity", "false");
    return Curry._1(onGoToMissions, undefined);
  };
  var redirectChecksActivity = function (activityId) {
    return Curry._1(redirect, /* ChecksActivity */15);
  };
  var userComingBackFromActivity = Belt_Option.getWithDefault(Belt_Option.map(InMemoryStore$LiteracyplanetClientFlags.useGetter("ranLcActivity"), (function (v) {
              return v === "true";
            })), false);
  React.useEffect((function () {
          if (hasLC) {
            
          } else {
            redirectJourneyMissions(undefined);
          }
          
        }), []);
  var state = ChecksLobbyQuery$LiteracyplanetClientSxEntry.use(!hasLC);
  if (!state) {
    return null;
  }
  var res = state._0;
  var currentCheck = Belt_Array.getBy(res.checks, (function (c) {
          return Caml_obj.caml_equal(c.activityComplete, false);
        }));
  if (currentCheck !== undefined) {
    return React.createElement(Page$ChecksLobby$LcLobby, {
                redirectChecksActivity: redirectChecksActivity,
                redirectJourneyMissions: redirectJourneyMissions,
                res: res,
                currentCheck: currentCheck
              });
  } else if (userComingBackFromActivity) {
    return React.createElement(Page$ChecksLobby$LcAssignmentComplete, {
                redirectJourneyMissions: redirectJourneyMissions
              });
  } else {
    return React.createElement(Page$ChecksLobby$MarkAssignationCompleteWhenAllActivitiesAreComplete, {
                checks: res.checks,
                dest: redirectJourneyMissions
              });
  }
}

var ChecksLobby = {
  LcLobby: LcLobby,
  LcAssignmentComplete: LcAssignmentComplete,
  RedirectComponent: RedirectComponent,
  MarkAssignationCompleteWhenAllActivitiesAreComplete: MarkAssignationCompleteWhenAllActivitiesAreComplete,
  make: Page$ChecksLobby
};

function Page$OverviewReport(Props) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.OverviewReportContainer.make, Lazy$LiteracyplanetClientSxEntry.OverviewReportContainer.makeProps(userId, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var OverviewReport = {
  make: Page$OverviewReport
};

function Page$ChecksActivity(Props) {
  var redirect = Props.redirect;
  var onBack = function (param) {
    return Curry._1(redirect, /* ChecksLobby */14);
  };
  return React.createElement(React.Suspense, {
              children: React.createElement(Lazy$LiteracyplanetClientSxEntry.ChecksActivityContainer.make, Lazy$LiteracyplanetClientSxEntry.ChecksActivityContainer.makeProps(onBack, undefined, undefined)),
              fallback: React.createElement("div", undefined)
            });
}

var ChecksActivity = {
  make: Page$ChecksActivity
};

function Page$Intrepizine(Props) {
  var optionalPostId = Props.optionalPostId;
  var pageToUrlWithState = Props.pageToUrlWithState;
  var state = Props.state;
  var postUrl = function (postId, title) {
    state["title"] = title;
    return Curry._2(pageToUrlWithState, {
                TAG: /* Intrepizine */13,
                _0: postId
              }, state);
  };
  var indexUrl = function (param) {
    return Curry._2(pageToUrlWithState, {
                TAG: /* Intrepizine */13,
                _0: undefined
              }, {});
  };
  var postTitle = Belt_Option.getWithDefault(Js_dict.get(state, "title"), "Article");
  return React.createElement("div", {
              className: "sx-iz"
            }, React.createElement(React.Suspense, {
                  children: React.createElement(Lazy$LiteracyplanetClientSxEntry.IntrepizineContainer.make, Lazy$LiteracyplanetClientSxEntry.IntrepizineContainer.makeProps(optionalPostId, postUrl, indexUrl, postTitle, undefined, undefined)),
                  fallback: React.createElement("div", undefined)
                }));
}

var Intrepizine = {
  make: Page$Intrepizine
};

function Page$DeepLinkActivities(Props) {
  var redirect = Props.redirect;
  var missionRef = Props.missionRef;
  return React.createElement(DeepLinkActivitiesScreen$LiteracyplanetClientSxEntry.make, {
              redirect: redirect,
              missionRef: missionRef
            });
}

var DeepLinkActivities = {
  make: Page$DeepLinkActivities
};

export {
  videoFolder ,
  vodFolder ,
  devPrefix ,
  source ,
  JourneyIntroCheck ,
  StoryverseIntroCheck ,
  StudentExperience ,
  StudentCollections ,
  StudentLibrary ,
  SecondaryMissions ,
  NoMatch ,
  Activity ,
  StudentActivity ,
  PlayAnyActivity ,
  AvatarShop ,
  StoryverseIntro ,
  Bog ,
  JourneyMissions ,
  JourneyActivities ,
  JourneyActivity ,
  Arcade ,
  ArcadeGame ,
  WordManiaGame ,
  MyWordsLists ,
  MyWordsAssign ,
  MyWordsEdit ,
  MyWordsNew ,
  MyWordsActivity ,
  ChecksLobby ,
  OverviewReport ,
  ChecksActivity ,
  Intrepizine ,
  DeepLinkActivities ,
  
}
/* uuid Not a pure module */
