// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Belt_List from "../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";

function stringArray(param) {
  return Json_encode.array((function (prim) {
                return prim;
              }), param);
}

function answerShape(a) {
  var param = Belt_List.toArray(a.answer);
  var param$1 = Belt_List.toArray(a.incorrectResponses);
  return Json_encode.object_({
              hd: [
                "questionCorrect",
                a.questionCorrect
              ],
              tl: {
                hd: [
                  "answer",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), param)
                ],
                tl: {
                  hd: [
                    "incorrectResponses",
                    Json_encode.array((function (prim) {
                            return prim;
                          }), param$1)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function markStateType(ims) {
  var questionIndex = ims[1];
  var mst = ims[0];
  if (mst) {
    return Json_encode.object_({
                hd: [
                  "questionIndex",
                  questionIndex
                ],
                tl: {
                  hd: [
                    "markStateType",
                    "BoolMarkState"
                  ],
                  tl: {
                    hd: [
                      "answerShape",
                      answerShape(mst._0)
                    ],
                    tl: /* [] */0
                  }
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "questionIndex",
                  questionIndex
                ],
                tl: {
                  hd: [
                    "markStateType",
                    "NotMarked"
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function markStates(param) {
  return Json_encode.array(markStateType, param);
}

function responseIdentifier(i) {
  switch (i.TAG | 0) {
    case /* SingleId */0 :
        return Json_encode.object_({
                    hd: [
                      "responseIdentifier",
                      "SingleId"
                    ],
                    tl: {
                      hd: [
                        "responseId",
                        i._0
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* IdPair */1 :
        return Json_encode.object_({
                    hd: [
                      "responseIdentifier",
                      "IdPair"
                    ],
                    tl: {
                      hd: [
                        "responseId",
                        i._0
                      ],
                      tl: {
                        hd: [
                          "responseId2",
                          i._1
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* Group */2 :
        return Json_encode.object_({
                    hd: [
                      "responseIdentifier",
                      "Group"
                    ],
                    tl: {
                      hd: [
                        "groupName",
                        i._0
                      ],
                      tl: {
                        hd: [
                          "link",
                          responseIdentifier(i._1)
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    
  }
}

function responsePayload(p) {
  if (p.TAG === /* BoolResponsePayload */0) {
    return Json_encode.object_({
                hd: [
                  "responsePayload",
                  "BoolResponsePayload"
                ],
                tl: {
                  hd: [
                    "responseIdentifier",
                    responseIdentifier(p._0)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "responsePayload",
                  "StrResponsePayload"
                ],
                tl: {
                  hd: [
                    "responseIdentifier",
                    responseIdentifier(p._0)
                  ],
                  tl: {
                    hd: [
                      "responseText",
                      p._1
                    ],
                    tl: /* [] */0
                  }
                }
              });
  }
}

function responseType(t) {
  if (t.TAG === /* ToggleResponse */0) {
    return Json_encode.object_({
                hd: [
                  "responseType",
                  "ToggleResponse"
                ],
                tl: {
                  hd: [
                    "responsePayload",
                    responsePayload(t._0)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "responseType",
                  "SetResponse"
                ],
                tl: {
                  hd: [
                    "responsePayload",
                    responsePayload(t._0)
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function responseArray(param) {
  return Json_encode.array(responseType, param);
}

function questionState(iqs) {
  var questionIndex = iqs[1];
  var questionStateType = iqs[0];
  if (questionStateType) {
    return Json_encode.object_({
                hd: [
                  "questionIndex",
                  questionIndex
                ],
                tl: {
                  hd: [
                    "questionStateType",
                    "QuestionAttempted"
                  ],
                  tl: {
                    hd: [
                      "responseArray",
                      Json_encode.array(responseType, questionStateType._0)
                    ],
                    tl: /* [] */0
                  }
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "questionIndex",
                  questionIndex
                ],
                tl: {
                  hd: [
                    "questionStateType",
                    "NotAttempted"
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function questionStates(param) {
  return Json_encode.array(questionState, param);
}

function state(state$1) {
  var match = state$1.mode;
  return Json.stringify(Json_encode.object_({
                  hd: [
                    "questionStates",
                    Json_encode.array(questionState, Belt_List.toArray(state$1.questionStates))
                  ],
                  tl: {
                    hd: [
                      "markStates",
                      Json_encode.array(markStateType, Belt_List.toArray(state$1.markStates))
                    ],
                    tl: {
                      hd: [
                        "mode",
                        match ? "Activity" : "Assessment"
                      ],
                      tl: {
                        hd: [
                          "questionsComplete",
                          state$1.questionsComplete
                        ],
                        tl: {
                          hd: [
                            "questionsCorrect",
                            state$1.questionsCorrect
                          ],
                          tl: {
                            hd: [
                              "questionsIncorrect",
                              state$1.questionsIncorrect
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }));
}

var Encode = {
  stringArray: stringArray,
  answerShape: answerShape,
  markStateType: markStateType,
  markStates: markStates,
  responseIdentifier: responseIdentifier,
  responsePayload: responsePayload,
  responseType: responseType,
  responseArray: responseArray,
  questionState: questionState,
  questionStates: questionStates,
  state: state
};

function responseIdentifier$1(json) {
  var match = Json_decode.field("responseIdentifier", Json_decode.string, json);
  switch (match) {
    case "Group" :
        return {
                TAG: /* Group */2,
                _0: Json_decode.field("groupName", Json_decode.string, json),
                _1: Json_decode.field("link", responseIdentifierLink, json)
              };
    case "IdPair" :
        return {
                TAG: /* IdPair */1,
                _0: Json_decode.field("responseId", Json_decode.string, json),
                _1: Json_decode.field("responseId2", Json_decode.string, json)
              };
    case "SingleId" :
        return {
                TAG: /* SingleId */0,
                _0: Json_decode.field("responseId", Json_decode.string, json)
              };
    default:
      return {
              TAG: /* SingleId */0,
              _0: Json_decode.field("responseId", Json_decode.string, json)
            };
  }
}

var responseIdentifierLink = responseIdentifier$1;

function responseIdentifierPayload(json) {
  return Json_decode.field("responseIdentifier", responseIdentifier$1, json);
}

function responsePayload$1(json) {
  var match = Json_decode.field("responsePayload", Json_decode.string, json);
  switch (match) {
    case "BoolResponsePayload" :
        return {
                TAG: /* BoolResponsePayload */0,
                _0: Json_decode.field("responseIdentifier", responseIdentifier$1, json)
              };
    case "StrResponsePayload" :
        break;
    default:
      
  }
  return {
          TAG: /* StrResponsePayload */1,
          _0: Json_decode.field("responseIdentifier", responseIdentifier$1, json),
          _1: Json_decode.field("responseText", Json_decode.string, json)
        };
}

function responseTypePayload(json) {
  return Json_decode.field("responsePayload", responsePayload$1, json);
}

function responseType$1(json) {
  var match = Json_decode.field("responseType", Json_decode.string, json);
  switch (match) {
    case "SetResponse" :
        return {
                TAG: /* SetResponse */1,
                _0: Json_decode.field("responsePayload", responsePayload$1, json)
              };
    case "ToggleResponse" :
        return {
                TAG: /* ToggleResponse */0,
                _0: Json_decode.field("responsePayload", responsePayload$1, json)
              };
    default:
      return {
              TAG: /* ToggleResponse */0,
              _0: Json_decode.field("responsePayload", responsePayload$1, json)
            };
  }
}

function responseArray$1(json) {
  return Json_decode.array(responseType$1, json);
}

function questionIndex(param) {
  return Json_decode.field("questionIndex", Json_decode.$$int, param);
}

function questionState$1(json) {
  var match = Json_decode.field("questionStateType", Json_decode.string, json);
  var tmp;
  switch (match) {
    case "NotAttempted" :
        tmp = /* NotAttempted */0;
        break;
    case "QuestionAttempted" :
        tmp = /* QuestionAttempted */{
          _0: Json_decode.field("responseArray", responseArray$1, json)
        };
        break;
    default:
      tmp = /* NotAttempted */0;
  }
  return [
          tmp,
          Json_decode.field("questionIndex", Json_decode.$$int, json)
        ];
}

function questionStates$1(json) {
  return Json_decode.array(questionState$1, json);
}

function stringArray$1(param) {
  return Json_decode.array(Json_decode.string, param);
}

function listOfString(f, json) {
  return Belt_List.fromArray(Json_decode.field(f, stringArray$1, json));
}

function answerShape$1(json) {
  return {
          questionCorrect: Json_decode.field("questionCorrect", Json_decode.bool, json),
          answer: listOfString("answer", json),
          incorrectResponses: listOfString("incorrectResponses", json)
        };
}

function markState(json) {
  var match = Json_decode.field("markStateType", Json_decode.string, json);
  var tmp;
  switch (match) {
    case "BoolMarkState" :
        tmp = /* BoolMarkState */{
          _0: Json_decode.field("answerShape", answerShape$1, json)
        };
        break;
    case "NotMarked" :
        tmp = /* NotMarked */0;
        break;
    default:
      tmp = /* NotMarked */0;
  }
  return [
          tmp,
          Json_decode.field("questionIndex", Json_decode.$$int, json)
        ];
}

function markStates$1(json) {
  return Json_decode.array(markState, json);
}

function decode(json) {
  var match = Json_decode.field("mode", Json_decode.string, json);
  var tmp;
  switch (match) {
    case "Activity" :
        tmp = /* Activity */1;
        break;
    case "Assessment" :
        tmp = /* Assessment */0;
        break;
    default:
      tmp = /* Activity */1;
  }
  return {
          questionStates: Belt_List.fromArray(Json_decode.field("questionStates", questionStates$1, json)),
          markStates: Belt_List.fromArray(Json_decode.field("markStates", markStates$1, json)),
          mode: tmp,
          questionsComplete: Json_decode.field("questionsComplete", Json_decode.$$int, json),
          questionsCorrect: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("questionsCorrect", Json_decode.$$int, param);
                    }), json), 0),
          questionsIncorrect: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("questionsIncorrect", Json_decode.$$int, param);
                    }), json), 0)
        };
}

function parseAndDecode(state) {
  return decode(JSON.parse(state));
}

function state$1(str) {
  try {
    return decode(JSON.parse(str));
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.log("deserialize error StateSerialization");
    console.log(e);
    return {
            questionStates: /* [] */0,
            markStates: /* [] */0,
            mode: /* Activity */1,
            questionsComplete: 0,
            questionsCorrect: 0,
            questionsIncorrect: 0
          };
  }
}

var Decode = {
  responseIdentifier: responseIdentifier$1,
  responseIdentifierLink: responseIdentifierLink,
  responseIdentifierPayload: responseIdentifierPayload,
  responsePayload: responsePayload$1,
  responseTypePayload: responseTypePayload,
  responseType: responseType$1,
  responseArray: responseArray$1,
  questionIndex: questionIndex,
  questionState: questionState$1,
  questionStates: questionStates$1,
  stringArray: stringArray$1,
  listOfString: listOfString,
  answerShape: answerShape$1,
  markState: markState,
  markStates: markStates$1,
  decode: decode,
  parseAndDecode: parseAndDecode,
  state: state$1
};

export {
  Encode ,
  Decode ,
  
}
/* No side effect */
