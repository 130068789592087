// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icons$LiteracyplanetClientSxEntry from "./layouts/Icons.bs.js";

function toDiagReferrerStr(referrer) {
  if (referrer) {
    return "missions";
  } else {
    return "video";
  }
}

function toDiagReferrer(referrer) {
  if (referrer === "video") {
    return /* IntroVideoReferrer */0;
  } else {
    return /* JourneyMissionsReferrer */1;
  }
}

function toIsAssessmentInt(assessment) {
  if (assessment) {
    return 1;
  } else {
    return 0;
  }
}

function toIsAssessment(assessment) {
  return assessment === "1";
}

function classicUrl(path) {
  return process.env.LP_URL + path;
}

function layoutToString(layout) {
  switch (layout) {
    case /* AuthWithMenu */0 :
        return "AuthWithMenu";
    case /* AuthWithTitleBar */1 :
        return "AuthWithTitleBar";
    case /* AuthWithTitleBarAndHeader */2 :
        return "AuthWithTitleBarAndHeader";
    case /* DarkBackground */3 :
        return "DarkBackground";
    case /* PlainBackground */4 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "PageTypes.res",
                85,
                2
              ],
              Error: new Error()
            };
    case /* AuthNoMenu */5 :
        return "AuthNoMenu";
    
  }
}

function backgroundToString(bg) {
  switch (bg) {
    case /* MainBackground */0 :
        return "MainBackground";
    case /* IntrepizineBackground */1 :
        return "IntrepizineBackground";
    case /* VoidBackground */2 :
        return "VoidBackground";
    case /* NoBackground */3 :
        return "NoBackground";
    
  }
}

function pageToIcon(page) {
  if (typeof page === "number") {
    if (page === /* StudentCollections */1) {
      return Icons$LiteracyplanetClientSxEntry.PrimaryCollectionsIcon.make;
    } else {
      return Icons$LiteracyplanetClientSxEntry.NoIcon.make;
    }
  } else if (page.TAG === /* StudentLibrary */1) {
    return Icons$LiteracyplanetClientSxEntry.Library.make;
  } else {
    return Icons$LiteracyplanetClientSxEntry.NoIcon.make;
  }
}

function pageToTitle(page) {
  if (typeof page === "number") {
    if (page === /* StudentCollections */1) {
      return "Collections";
    } else {
      return "LiteracyPlanet";
    }
  } else if (page.TAG === /* StudentLibrary */1) {
    return "Library";
  } else {
    return "LiteracyPlanet";
  }
}

export {
  toDiagReferrerStr ,
  toDiagReferrer ,
  toIsAssessmentInt ,
  toIsAssessment ,
  classicUrl ,
  layoutToString ,
  backgroundToString ,
  pageToIcon ,
  pageToTitle ,
  
}
/* Icons-LiteracyplanetClientSxEntry Not a pure module */
