// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PageTypes$LiteracyplanetClientSxEntry from "../PageTypes.bs.js";
import * as ContextMaker$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ContextMaker.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as BlankUserLayout$LiteracyplanetClientSxEntry from "./BlankUserLayout.bs.js";
import * as WrapSelectorComponent$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/WrapSelectorComponent.bs.js";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./StudentExperienceWrap.bs.js";

var LayoutSelectorApi = {
  name: "StudentExperienceLayout"
};

var include = ContextMaker$LiteracyplanetClientFlags.Make(LayoutSelectorApi);

var Provider = include.Provider;

var use = include.use;

var Context_initialContext = include.initialContext;

var Context_context = include.context;

var Context_P = include.P;

var Context_ContextMakerProviderNotFound = include.ContextMakerProviderNotFound;

var Context = {
  LayoutSelectorApi: LayoutSelectorApi,
  initialContext: Context_initialContext,
  context: Context_context,
  P: Context_P,
  Provider: Provider,
  ContextMakerProviderNotFound: Context_ContextMakerProviderNotFound,
  use: use
};

var span = {
  padding: "8px"
};

var noStyle = {};

var selected = {
  background: "white",
  color: "black"
};

var panel = {
  background: "black",
  bottom: "0",
  color: "white",
  cursor: "pointer",
  padding: "5px",
  position: "fixed",
  zIndex: "1000",
  opacity: ".8"
};

function StudentExperienceLayout$LayoutSelector(Props) {
  var match = Curry._1(use, undefined);
  var changeLayout = match.setLayout;
  var changeBackground = match.setBackground;
  var layout = match.layout;
  var background = match.background;
  var pageLayoutLink = function (ex) {
    return React.createElement("a", {
                style: ex === layout ? selected : noStyle,
                onClick: (function (param) {
                    return Curry._1(changeLayout, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, PageTypes$LiteracyplanetClientSxEntry.layoutToString(ex)));
  };
  var pageBackgroundLink = function (ex) {
    return React.createElement("a", {
                style: ex === background ? selected : noStyle,
                onClick: (function (param) {
                    return Curry._1(changeBackground, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, PageTypes$LiteracyplanetClientSxEntry.backgroundToString(ex)));
  };
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var testingLayouts = Curry._1(hasFlag, "DeveloperTestingLayouts");
  if (testingLayouts) {
    return React.createElement("div", {
                style: panel
              }, React.createElement(WrapSelectorComponent$LiteracyplanetClientFlags.make, {}), React.createElement("div", undefined, pageLayoutLink(/* AuthWithMenu */0), pageLayoutLink(/* AuthWithTitleBar */1), pageLayoutLink(/* AuthWithTitleBarAndHeader */2), pageLayoutLink(/* DarkBackground */3), pageLayoutLink(/* AuthNoMenu */5)), React.createElement("div", undefined, pageBackgroundLink(/* MainBackground */0), pageBackgroundLink(/* IntrepizineBackground */1), pageBackgroundLink(/* NoBackground */3), pageBackgroundLink(/* VoidBackground */2)));
  } else {
    return null;
  }
}

var LayoutSelector = {
  span: span,
  noStyle: noStyle,
  selected: selected,
  panel: panel,
  make: StudentExperienceLayout$LayoutSelector
};

function StudentExperienceLayout(Props) {
  var redirect = Props.redirect;
  var layout = Props.layout;
  var background = Props.background;
  var page = Props.page;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var children = Props.children;
  var match = React.useState(function () {
        return layout;
      });
  var setLayout = match[1];
  var layoutOverride = match[0];
  var match$1 = React.useState(function () {
        return background;
      });
  var setBackground = match$1[1];
  var bgOverride = match$1[0];
  React.useEffect((function () {
          Curry._1(setLayout, (function (param) {
                  return layout;
                }));
          
        }), [layout]);
  React.useEffect((function () {
          Curry._1(setBackground, (function (param) {
                  return background;
                }));
          
        }), [background]);
  return React.createElement(Provider.make, {
              value: {
                background: bgOverride,
                layout: layoutOverride,
                setBackground: (function (bg) {
                    return Curry._1(setBackground, (function (param) {
                                  return bg;
                                }));
                  }),
                setLayout: (function (l) {
                    return Curry._1(setLayout, (function (param) {
                                  return l;
                                }));
                  })
              },
              children: null
            }, React.createElement(StudentExperienceLayout$LayoutSelector, {}), layoutOverride >= 5 ? React.createElement(BlankUserLayout$LiteracyplanetClientSxEntry.make, {
                    children: children
                  }) : React.createElement(StudentExperienceWrap$LiteracyplanetClientSxEntry.make, {
                    redirect: redirect,
                    background: bgOverride,
                    layout: layoutOverride,
                    page: page,
                    children: children,
                    isPageBlacklisted: isPageBlacklisted
                  }));
}

var make = StudentExperienceLayout;

var $$default = StudentExperienceLayout;

export {
  Context ,
  LayoutSelector ,
  make ,
  $$default ,
  $$default as default,
  
}
/* include Not a pure module */
