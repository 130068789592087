// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";

function parseImage(r) {
  return {
          source: r.source
        };
}

function parsePhonemeAudio(r) {
  return {
          source: r.source
        };
}

function parseAudio(r) {
  return {
          source: r.source
        };
}

function parseVocal(vocal) {
  return {
          id: vocal.id,
          name: vocal.name,
          audio: {
            source: vocal.audio.source
          }
        };
}

function parseVocals(vocals) {
  return Belt_Array.map(vocals, parseVocal);
}

function parseExampleSentence(word) {
  return {
          id: word.exampleSentence.id,
          text: word.exampleSentence.text,
          words: Belt_Array.map(word.exampleSentence.words, parseVocal)
        };
}

function parseWord(word) {
  return {
          id: word.id,
          name: word.name,
          audio: {
            source: word.audio.source
          },
          exampleSentence: parseExampleSentence(word)
        };
}

function parseWordList(data) {
  return {
          TAG: /* Ok */0,
          _0: {
            words: Belt_Array.map(data.list.words, parseWord)
          }
        };
}

function makeLocation(s) {
  return s.replace("Beginning", "begins").replace("Ending", "ends");
}

function parseSoundTrainPhoneme(phoneme) {
  return {
          name: phoneme.name,
          location: makeLocation(phoneme.location),
          audio: {
            source: phoneme.audio.source
          }
        };
}

function parseAoundTrainword(word) {
  return {
          name: word.name,
          isCorrect: word.isCorrect,
          image: {
            source: word.image.source
          },
          audio: {
            source: word.audio.source,
            phonemeSource: word.audio.phonemeSource
          }
        };
}

function parseSoundTrainQuestion(question) {
  return {
          phoneme: parseSoundTrainPhoneme(question.phoneme),
          words: Belt_Array.map(question.words, parseAoundTrainword)
        };
}

function parseSoundTrainStation(station) {
  return {
          questions: Belt_Array.map(station.questions, parseSoundTrainQuestion)
        };
}

function parseSoundTrain(content) {
  return {
          TAG: /* Ok */0,
          _0: {
            stations: Belt_Array.map(content.stations, parseSoundTrainStation)
          }
        };
}

function parseWamPhoneme(p) {
  return {
          name: p.name,
          isCorrect: p.isCorrect,
          isFlipped: p.isFlipped
        };
}

function parseWAMPhonemes(phonemes) {
  return Belt_Array.map(phonemes, parseWamPhoneme);
}

function parseWamQuestion(q) {
  return {
          audio: {
            source: q.audio.source
          },
          name: q.name,
          phonemes: Belt_Array.map(q.phonemes, parseWamPhoneme)
        };
}

function parseWAMQuestions(questions) {
  return Belt_Array.map(questions, parseWamQuestion);
}

function parseWhackAMunk(content) {
  return {
          TAG: /* Ok */0,
          _0: {
            questions: Belt_Array.map(content.questions, parseWamQuestion)
          }
        };
}

function parseMonsterChefPhoneme(p) {
  return {
          name: p.name,
          audio: {
            source: p.audio.source
          }
        };
}

function parseMonsterChefPhonemes(phonemes) {
  return Belt_Array.map(phonemes, parseMonsterChefPhoneme);
}

function parseMonsterChefWord(w) {
  return {
          id: w.id,
          name: w.name,
          audio: {
            source: w.audio.source
          },
          phonemes: Belt_Array.map(w.phonemes, parseMonsterChefPhoneme),
          distractorPhonemes: Belt_Array.map(w.distractorPhonemes, parseMonsterChefPhoneme)
        };
}

function parseMonsterChefWords(words) {
  return Belt_Array.map(words, parseMonsterChefWord);
}

function parseMonsterChef(content) {
  return {
          TAG: /* Ok */0,
          _0: {
            words: Belt_Array.map(content.words, parseMonsterChefWord)
          }
        };
}

function parseInternalStatic(data) {
  return {
          TAG: /* Ok */0,
          _0: {
            id: data.id,
            extraVocalDirectory: data.extraVocalDirectory,
            phonemeVocalDirectory: data.phonemeVocalDirectory,
            wordVocalDirectory: data.wordVocalDirectory,
            letterVocalDirectory: data.letterVocalDirectory
          }
        };
}

function parseUnsafeData(data) {
  return {
          TAG: /* Ok */0,
          _0: {
            id: data.id,
            data: data.data,
            extraVocalDirectory: data.extraVocalDirectory,
            phonemeVocalDirectory: data.phonemeVocalDirectory,
            wordVocalDirectory: data.wordVocalDirectory,
            letterVocalDirectory: data.letterVocalDirectory
          }
        };
}

var currentStudentWithAvatar = {
  avatar: undefined
};

function wordGame(currentStudent, wordGameContent) {
  return {
          currentStudent: currentStudent,
          questionIndex: 0,
          numCorrect: 0,
          activityMetadata: {
            title: ""
          },
          extraVocals: [],
          wordGameContent: wordGameContent
        };
}

function wordGameAvatar(w) {
  return wordGame(currentStudentWithAvatar, w);
}

export {
  parseImage ,
  parsePhonemeAudio ,
  parseAudio ,
  parseVocal ,
  parseVocals ,
  parseExampleSentence ,
  parseWord ,
  parseWordList ,
  makeLocation ,
  parseSoundTrainPhoneme ,
  parseAoundTrainword ,
  parseSoundTrainQuestion ,
  parseSoundTrainStation ,
  parseSoundTrain ,
  parseWamPhoneme ,
  parseWAMPhonemes ,
  parseWamQuestion ,
  parseWAMQuestions ,
  parseWhackAMunk ,
  parseMonsterChefPhoneme ,
  parseMonsterChefPhonemes ,
  parseMonsterChefWord ,
  parseMonsterChefWords ,
  parseMonsterChef ,
  parseInternalStatic ,
  parseUnsafeData ,
  currentStudentWithAvatar ,
  wordGame ,
  wordGameAvatar ,
  
}
/* No side effect */
