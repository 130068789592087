// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var unsafePlaceholder = {};

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7) {
  var tmp = {
    redirect: prim0,
    layout: prim1,
    background: prim2,
    page: prim3,
    isPageBlacklisted: prim4,
    children: prim5
  };
  if (prim6 !== undefined) {
    tmp.key = prim6;
  }
  return tmp;
}

var make = React.lazy(function (param) {
      return import("./layouts/StudentExperienceLayout.bs.js");
    });

var StudentExperienceLayout = {
  unsafePlaceholder: unsafePlaceholder,
  UnsafePlaceholder: unsafePlaceholder,
  makeProps: makeProps,
  make: make
};

var unsafePlaceholder$1 = {};

function makeProps$1(prim0, prim1) {
  var tmp = {};
  if (prim0 !== undefined) {
    tmp.key = prim0;
  }
  return tmp;
}

var make$1 = React.lazy(function (param) {
      return import("@literacyplanet/client-sx-secondary-missions");
    });

var SecondaryMissions = {
  unsafePlaceholder: unsafePlaceholder$1,
  UnsafePlaceholder: unsafePlaceholder$1,
  makeProps: makeProps$1,
  make: make$1
};

var unsafePlaceholder$2 = {};

function makeProps$2(prim0, prim1, prim2, prim3, prim4, prim5) {
  var tmp = {
    bookId: prim0,
    groupIndex: prim1,
    onChangeBookId: prim2,
    onChangeGroupIndex: prim3
  };
  if (prim4 !== undefined) {
    tmp.key = prim4;
  }
  return tmp;
}

var make$2 = React.lazy(function (param) {
      return import("@literacyplanet/client-sx-library");
    });

var Library = {
  unsafePlaceholder: unsafePlaceholder$2,
  UnsafePlaceholder: unsafePlaceholder$2,
  makeProps: makeProps$2,
  make: make$2
};

var unsafePlaceholder$3 = {};

function makeProps$3(prim0, prim1, prim2) {
  var tmp = {
    onClickGame: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var make$3 = React.lazy(function (param) {
      return import("./arcade/ArcadeContainer.bs.js");
    });

var Arcade = {
  unsafePlaceholder: unsafePlaceholder$3,
  UnsafePlaceholder: unsafePlaceholder$3,
  makeProps: makeProps$3,
  make: make$3
};

var unsafePlaceholder$4 = {};

function makeProps$4(prim0, prim1, prim2, prim3, prim4, prim5) {
  var tmp = {
    onExit: prim0,
    locale: prim1,
    gameKind: prim2,
    userId: prim3
  };
  if (prim4 !== undefined) {
    tmp.key = prim4;
  }
  return tmp;
}

var make$4 = React.lazy(function (param) {
      return import("./arcade/ArcadeGameContainer.bs.js");
    });

var ArcadeGame = {
  unsafePlaceholder: unsafePlaceholder$4,
  UnsafePlaceholder: unsafePlaceholder$4,
  makeProps: makeProps$4,
  make: make$4
};

var unsafePlaceholder$5 = {};

function makeProps$5(prim0, prim1, prim2) {
  var tmp = {
    onExit: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var make$5 = React.lazy(function (param) {
      return import("./arcade/WordManiaGameContainer.bs.js");
    });

var WordManiaGame = {
  unsafePlaceholder: unsafePlaceholder$5,
  UnsafePlaceholder: unsafePlaceholder$5,
  makeProps: makeProps$5,
  make: make$5
};

var unsafePlaceholder$6 = {};

function makeProps$6(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {
    userId: prim0,
    isProAccessLocked: prim1,
    onExit: prim2
  };
  if (prim3 !== undefined) {
    tmp.key = prim3;
  }
  return tmp;
}

var make$6 = React.lazy(function (param) {
      return import("./shop/ShopContainer.bs.js");
    });

var AvatarShop = {
  unsafePlaceholder: unsafePlaceholder$6,
  UnsafePlaceholder: unsafePlaceholder$6,
  makeProps: makeProps$6,
  make: make$6
};

var unsafePlaceholder$7 = {};

function makeProps$7(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    userId: prim0,
    onMaybeGotoCollections: prim1,
    onActivityNotFound: prim2,
    onGoToMissions: prim3,
    onExit: prim4,
    studentActivityId: prim5,
    accent: prim6,
    locale: prim7
  };
  if (prim8 !== undefined) {
    tmp.key = prim8;
  }
  return tmp;
}

var make$7 = React.lazy(function (param) {
      return import("@literacyplanet/client-sx-activity/src/flipper/FlipperMissionsActivityLauncher.bs.js");
    });

var FlipperActivity = {
  unsafePlaceholder: unsafePlaceholder$7,
  UnsafePlaceholder: unsafePlaceholder$7,
  makeProps: makeProps$7,
  make: make$7
};

var unsafePlaceholder$8 = {};

function makeProps$8(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11) {
  var tmp = {
    contentId: prim0,
    contentVersion: prim1,
    contentKind: prim2,
    kind: prim3,
    locale: prim4,
    accent: prim5,
    title: prim6,
    onExit: prim7,
    onGoToMissions: prim8,
    onMaybeGotoCollections: prim9
  };
  if (prim10 !== undefined) {
    tmp.key = prim10;
  }
  return tmp;
}

var make$8 = React.lazy(function (param) {
      return import("@literacyplanet/client-sx-activity/src/flipper/FlipperPlayAnyMissionActivityLauncher.bs.js");
    });

var FlipperPlayAnyActivity = {
  unsafePlaceholder: unsafePlaceholder$8,
  UnsafePlaceholder: unsafePlaceholder$8,
  makeProps: makeProps$8,
  make: make$8
};

var unsafePlaceholder$9 = {};

function makeProps$9(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7) {
  var tmp = {
    onNewList: prim0,
    onEditList: prim1,
    onSetPage: prim2,
    page: prim3,
    searchName: prim4,
    onSearchName: prim5
  };
  if (prim6 !== undefined) {
    tmp.key = prim6;
  }
  return tmp;
}

var make$9 = React.lazy(function (param) {
      return import("./my_words/MyWordsIndexContainer.bs.js");
    });

var MyWordsIndexContainer = {
  unsafePlaceholder: unsafePlaceholder$9,
  UnsafePlaceholder: unsafePlaceholder$9,
  makeProps: makeProps$9,
  make: make$9
};

var unsafePlaceholder$10 = {};

function makeProps$10(prim0, prim1, prim2, prim3, prim4, prim5) {
  var tmp = {
    onBack: prim0,
    onSave: prim1,
    customWordListId: prim2,
    userId: prim3
  };
  if (prim4 !== undefined) {
    tmp.key = prim4;
  }
  return tmp;
}

var make$10 = React.lazy(function (param) {
      return import("./my_words/MyWordsEditContainer.bs.js");
    });

var MyWordsEditContainer = {
  unsafePlaceholder: unsafePlaceholder$10,
  UnsafePlaceholder: unsafePlaceholder$10,
  makeProps: makeProps$10,
  make: make$10
};

var unsafePlaceholder$11 = {};

function makeProps$11(prim0, prim1, prim2, prim3, prim4, prim5, prim6) {
  var tmp = {
    onBack: prim0,
    onEditList: prim1,
    onPlayActivity: prim2,
    customWordListId: prim3,
    userId: prim4
  };
  if (prim5 !== undefined) {
    tmp.key = prim5;
  }
  return tmp;
}

var make$11 = React.lazy(function (param) {
      return import("./my_words/MyWordsAssignContainer.bs.js");
    });

var MyWordsAssignContainer = {
  unsafePlaceholder: unsafePlaceholder$11,
  UnsafePlaceholder: unsafePlaceholder$11,
  makeProps: makeProps$11,
  make: make$11
};

var unsafePlaceholder$12 = {};

function makeProps$12(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {
    gameId: prim0,
    onBack: prim1,
    customWordListId: prim2
  };
  if (prim3 !== undefined) {
    tmp.key = prim3;
  }
  return tmp;
}

var make$12 = React.lazy(function (param) {
      return import("./my_words/MyWordsActivityContainer.bs.js");
    });

var MyWordsActivityContainer = {
  unsafePlaceholder: unsafePlaceholder$12,
  UnsafePlaceholder: unsafePlaceholder$12,
  makeProps: makeProps$12,
  make: make$12
};

var unsafePlaceholder$13 = {};

function makeProps$13(prim0, prim1, prim2) {
  var tmp = {
    userId: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var make$13 = React.lazy(function (param) {
      return import("./OverviewReportContainer.bs.js");
    });

var OverviewReportContainer = {
  unsafePlaceholder: unsafePlaceholder$13,
  UnsafePlaceholder: unsafePlaceholder$13,
  makeProps: makeProps$13,
  make: make$13
};

var unsafePlaceholder$14 = {};

function makeProps$14(prim0, prim1, prim2) {
  var tmp = {
    onBack: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var make$14 = React.lazy(function (param) {
      return import("./checks/ChecksActivityContainer.bs.js");
    });

var ChecksActivityContainer = {
  unsafePlaceholder: unsafePlaceholder$14,
  UnsafePlaceholder: unsafePlaceholder$14,
  makeProps: makeProps$14,
  make: make$14
};

var unsafePlaceholder$15 = {};

function makeProps$15(prim0, prim1, prim2, prim3, prim4, prim5) {
  var tmp = {
    optionalPostId: prim0,
    postUrl: prim1,
    indexUrl: prim2,
    postTitle: prim3
  };
  if (prim4 !== undefined) {
    tmp.key = prim4;
  }
  return tmp;
}

var make$15 = React.lazy(function (param) {
      return import("@literacyplanet/client-flags/src/IntrepizineContainer.bs.js");
    });

var IntrepizineContainer = {
  unsafePlaceholder: unsafePlaceholder$15,
  UnsafePlaceholder: unsafePlaceholder$15,
  makeProps: makeProps$15,
  make: make$15
};

var unsafePlaceholder$16 = {};

function makeProps$16(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {
    onExit: prim0,
    onChangeNavigation: prim1,
    nav: prim2
  };
  if (prim3 !== undefined) {
    tmp.key = prim3;
  }
  return tmp;
}

var make$16 = React.lazy(function (param) {
      return import("./book_of_goings/BogContainer.bs.js");
    });

var BogContainer = {
  unsafePlaceholder: unsafePlaceholder$16,
  UnsafePlaceholder: unsafePlaceholder$16,
  makeProps: makeProps$16,
  make: make$16
};

var unsafePlaceholder$17 = {};

function makeProps$17(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10) {
  var tmp = {
    userId: prim0,
    onClickShop: prim1,
    onClickArcade: prim2,
    isShopLocked: prim3,
    isArcadeLocked: prim4,
    isMiniGameLocked: prim5,
    onToggleProModal: prim6,
    onToggleLockedModal: prim7,
    isProRequiredForMiniGame: prim8
  };
  if (prim9 !== undefined) {
    tmp.key = prim9;
  }
  return tmp;
}

var make$17 = React.lazy(function (param) {
      return import("./collections/CollectionsContainer.bs.js");
    });

var CollectionsContainer = {
  unsafePlaceholder: unsafePlaceholder$17,
  UnsafePlaceholder: unsafePlaceholder$17,
  makeProps: makeProps$17,
  make: make$17
};

export {
  StudentExperienceLayout ,
  SecondaryMissions ,
  Library ,
  Arcade ,
  ArcadeGame ,
  WordManiaGame ,
  AvatarShop ,
  FlipperActivity ,
  FlipperPlayAnyActivity ,
  MyWordsIndexContainer ,
  MyWordsEditContainer ,
  MyWordsAssignContainer ,
  MyWordsActivityContainer ,
  OverviewReportContainer ,
  ChecksActivityContainer ,
  IntrepizineContainer ,
  BogContainer ,
  CollectionsContainer ,
  
}
/* make Not a pure module */
